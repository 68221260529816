import { useEffect, useRef, useState } from "react";
import {
    Box, Button, Checkbox, FormControl,
    Icon, InputLabel, ListItemText, MenuItem,
    OutlinedInput, Paper, Select, SelectChangeEvent,
    TextField, Typography
} from "@mui/material";
import { enqueueSnackbar } from "notistack";

import { ICourseResponse } from "../../interfaces";
import { CourseService } from "../../services/api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


interface IExtensionProjectComputeHoursToolbarProps {
    userName?: string;
    registration?: string;
    listCourseIds?: string;
    finalized?: string;
    onChangeUserName?: (newText: string) => void;
    onChangeRegistration?: (newText: string) => void;
    onChangeListCourseIds?: (newText: string) => void;
    onChangeFinalized?: (newText: string) => void;
    showButton?: boolean;
    onClickSearch: () => void;
}

export const ExtensionProjectComputeHoursToolbar: React.FC<IExtensionProjectComputeHoursToolbarProps> = ({
    userName = "",
    registration = "",
    listCourseIds = "",
    finalized = "",
    onChangeUserName,
    onChangeRegistration,
    onChangeListCourseIds,
    onChangeFinalized,
    showButton = true,
    onClickSearch
}) => {

    const [selectedCourseIds, setSelectedCourseIds] = useState<number[]>([]);
    const [allCourses, setAllCourses] = useState<ICourseResponse[]>([]);

    const countRef = useRef(0);
    
    useEffect(() => {

        if (process.env.NODE_ENV === 'development') {
            if (countRef.current === 0) {
                countRef.current = 1;
                return;
            }
        }

        handleGetAllCourses();

        if (listCourseIds) {
            setSelectedCourseIds(listCourseIds.split(',').map(v => parseInt(v)));
        }

    }, []);

    const handleCourseChange = (event: SelectChangeEvent<number[]>) => {
        const value = event.target.value;

        onChangeListCourseIds?.(Array.isArray(value) ? value.join(',') : value);

        setSelectedCourseIds(
            // Se for string, converte para array, caso contrário, usa o array de números
            typeof value === 'string' ? value.split(',').map(v => parseInt(v)) : value,
        );
    };

    const handleGetAllCourses = () => {

        if (allCourses.length === 0) {

            CourseService.getAll()
                .then((result) => {

                    if (result instanceof Error) {
                        return;
                    }

                    setAllCourses(result);

                })
        }
    }

    return (
        <Box
            gap={1}
            marginX={1}
            padding={1}
            paddingX={2}
            display="flex"
            alignItems="center"
            component={Paper}
            flexWrap="wrap"
        >

            <TextField
                size="small"
                value={userName}
                onChange={(e) => onChangeUserName?.(e.target.value)}
                placeholder={"Nome do usuário"}
            />

            <TextField
                size="small"
                value={registration}
                onChange={(e) => onChangeRegistration?.(e.target.value)}
                placeholder={"Matrícula"}
            />

            <FormControl sx={{ m: 1, width: 300 }} size="small">
                <InputLabel>Curso</InputLabel>
                <Select
                    multiple
                    value={selectedCourseIds}
                    onChange={handleCourseChange}
                    input={<OutlinedInput label="Curso" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {allCourses.map((course) => (
                        <MenuItem key={course.id} value={course.id}>
                            <Checkbox checked={selectedCourseIds.indexOf(course.id) > -1} />
                            <ListItemText primary={course.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel>Finalizado?</InputLabel>
                <Select
                    value={finalized}
                    MenuProps={MenuProps}
                    input={<OutlinedInput label="Finalizado?" />}
                    onChange={(e) => onChangeFinalized?.(e.target.value)}
                >
                    <MenuItem key="false" value="false">
                        <ListItemText primary="Não" />
                    </MenuItem>

                    <MenuItem key="true" value="true">
                        <ListItemText primary="Sim" />
                    </MenuItem>
                </Select>
            </FormControl>

            <Box
                flex={1}
                gap={1}
                display="flex" justifyContent="end"
            >
                {
                    showButton &&
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<Icon>search</Icon>}
                        onClick={onClickSearch}
                    >
                        Pesquisar
                    </Button>
                }
            </Box>

        </Box>
    );
}
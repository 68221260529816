import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import {
    Icon, IconButton, LinearProgress,
    Pagination, Paper, Table, TableBody, TableCell,
    TableContainer, TableFooter, TableHead, TableRow, Tooltip, Typography
} from "@mui/material";
import { enqueueSnackbar } from "notistack";

import { ListingExtensionProjectToolbar, ExtensionProjectStatusChip } from "../../shared/components";
import { useAuthContext } from "../../shared/contexts";
import { Environment } from "../../shared/environment";
import { IExtensionProjectSearchResponse, Course_ExtensionProjectResponse } from "../../shared/interfaces";
import { BasePageLayout } from "../../shared/layouts";
import { ExtensionProjectService, ReportManagementService } from "../../shared/services/api";

export const ExtensionProjectsList: React.FC = () => {

    const { roles, courses } = useAuthContext();
    const [searchParam, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [rowCount, setRowCount] = useState<number>(0);
    const [extensionProjectList, setExtensionProjectList] = useState<IExtensionProjectSearchResponse>();

    const searchText = useMemo(() => {
        return searchParam.get('search') || '';
    }, [searchParam]);

    const page = useMemo(() => {
        return Number(searchParam.get('page') || '1');
    }, [searchParam]);

    const status = useMemo(() => {

        if (roles?.includes(Environment.ROLE_ALUNO)) {
            return "4";
        }

        return searchParam.get('status') || '';
    }, [searchParam]);

    const openForApplications = useMemo(() => {

        if (roles?.includes(Environment.ROLE_ALUNO)) {
            return "true";
        }

        return searchParam.get('openForApplications') || "";
    }, [searchParam]);

    const listCourseId = useMemo(() => {

        if (roles?.includes(Environment.ROLE_ALUNO)) {
            return Array.isArray(courses) ? courses.join(",") : courses;
        }

        return searchParam.get('listCourseId') || "";
    }, [searchParam]);

    const countRef = useRef(0);

    useEffect(() => {

        if (process.env.NODE_ENV === 'development') {
            if (countRef.current === 0) {
                countRef.current = 1;
                return;
            }
        }
        handleSearch();
    }, [page]);

    const handleSearch = () => {

        setLoading(true);

        ExtensionProjectService.search(
            searchText,
            0,
            status === "" ? undefined : Number(status),
            openForApplications === "" ? undefined : openForApplications === "true",
            listCourseId === "" ? undefined : listCourseId,
            undefined, page - 1)
            .then((result) => {

                if (result instanceof Error) {
                    return;
                }

                setExtensionProjectList(result);
                setRowCount(result.rowsCount);

            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleGenerateReport = () => {

        setLoading(true);

        ReportManagementService.extensionProjectsByCourse(
            searchText,
            0,
            status === "" ? undefined : Number(status),
            openForApplications === "" ? undefined : openForApplications === "true",
            listCourseId === "" ? undefined : listCourseId,
            undefined, page - 1)
            .then((result) => {

                if (result instanceof Error) {
                    return;
                }

            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleGenerateCertificate = () => {

        setLoading(true);

        ReportManagementService.generateStudentCertificate()
            .then((result) => {

                if (result instanceof Error) {
                    enqueueSnackbar(
                        <Typography>
                            {result.message}
                        </Typography>,
                        { variant: 'error' })
                        
                    return;
                }

            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <BasePageLayout
            title="Projetos de Extensão"
            toolbar={(
                <ListingExtensionProjectToolbar
                    searchText={searchText}
                    status={status}
                    openForApplication={openForApplications}
                    listCourseIds={listCourseId}
                    showButton={roles?.includes(Environment.ROLE_PROFESSOR)}

                    onChangeSearchText={text => setSearchParams({
                        search: text,
                        status: status,
                        openForApplications: openForApplications,
                        listCourseId: listCourseId,
                        page: page.toString()
                    }, { replace: true })}

                    onChangeStatus={st => setSearchParams({
                        search: searchText,
                        status: st,
                        openForApplications: openForApplications,
                        listCourseId: listCourseId,
                        page: page.toString()
                    }, { replace: true })}

                    onChangeOpenForApplication={ofa => setSearchParams({
                        search: searchText,
                        status: status,
                        openForApplications: ofa,
                        listCourseId: listCourseId,
                        page: page.toString()
                    }, { replace: true })}

                    onChangeListCourseIds={cl => setSearchParams({
                        search: searchText,
                        status: status,
                        openForApplications: openForApplications,
                        listCourseId: cl,
                        page: page.toString()
                    }, { replace: true })}

                    isStudent={roles?.includes(Environment.ROLE_ALUNO)}
                    onClickButtonAdd={() => navigate("/extension-projects/new/0")}
                    onClickSearch={() => handleSearch()}
                    onClickReport={() => handleGenerateReport()}
                    disableButtonReport={rowCount === 0 || loading}
                    disableButtonSearch={loading}
                    disableButtonGenerateCertificate={loading}
                />
            )}>
            <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
                {
                    loading &&
                    <LinearProgress variant='indeterminate' />
                }
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>Responsável</TableCell>
                            <TableCell>Cursos</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Inscrição</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {extensionProjectList?.items?.map(row => (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>Professor {row.responsibleUser?.name}</TableCell>
                                <TableCell>
                                    {
                                        row.course_ExtensionProjects?.map((cep: Course_ExtensionProjectResponse, index: number) => (
                                            <Tooltip title={`${cep?.course?.name}`} key={cep.courseId}>
                                                <span>
                                                    {index + 1 === row.course_ExtensionProjects?.length ? `${cep?.course?.acronym}` : `${cep?.course?.acronym}, `}
                                                </span>
                                            </Tooltip>
                                        ))
                                    }
                                </TableCell>
                                <TableCell>
                                    <ExtensionProjectStatusChip status={row.status} />
                                </TableCell>
                                <TableCell>{row.openForApplications ? "Aberta" : "Fechada"}</TableCell>
                                <TableCell>
                                    <IconButton size="small" onClick={() => navigate(`/extension-projects/${row.id}`)}>
                                        <Icon>remove_red_eye</Icon>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                    {rowCount === 0 && !loading && (
                        <caption>Nenhum registro encontrado</caption>
                    )}

                    <TableFooter>
                        {
                            rowCount > 0 &&
                            rowCount > Environment.ROWS_LIMIT10 &&
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Pagination
                                        page={page}
                                        count={Math.ceil(rowCount / Environment.ROWS_LIMIT10)}
                                        onChange={(_, newPage) => {
                                            setSearchParams({ search: searchText, status: status, openForApplications: openForApplications, page: newPage.toString() }, { replace: true })
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        }
                    </TableFooter>
                </Table>
            </TableContainer>
        </BasePageLayout>
    )

}
import { useEffect, useRef, useState } from "react";
import {
    Box, Button, Checkbox, FormControl,
    Icon, IconButton, InputLabel, ListItemText,
    Menu, MenuItem, OutlinedInput, Paper, Select,
    SelectChangeEvent, TextField, Theme, Typography,
    alpha, styled, useMediaQuery
} from "@mui/material";
import { MenuProps } from '@mui/material/Menu';
import { enqueueSnackbar } from "notistack";
import { KeyboardArrowDown } from "@mui/icons-material";

import { Environment } from "../../environment";
import { ICourseResponse } from "../../interfaces";
import { CourseService } from "../../services/api";
import { Conditional } from "../conditional/conditional";


const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const SelectProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const statusEnum =
    [
        {
            "id": 0,
            "name": "Pendente de aprovação"
        },
        {
            "id": 2,
            "name": "Reprovado para revisão"
        },
        {
            "id": 3,
            "name": "Reprovado"
        },
        {
            "id": 4,
            "name": "Em andamento"
        },
        {
            "id": 6,
            "name": "Concluído"
        }
    ];


interface IListingToolbarProps {
    searchText?: string;
    status?: string;
    openForApplication?: string;
    listCourseIds?: string;
    onChangeSearchText?: (newText: string) => void;
    onChangeStatus?: (newText: string) => void;
    onChangeOpenForApplication?: (newText: string) => void;
    onChangeListCourseIds?: (newText: string) => void;
    textButton?: string;
    showButton?: boolean;
    isStudent?: boolean;
    disableButtonReport?: boolean;
    disableButtonSearch?: boolean;
    disableButtonGenerateCertificate?: boolean;
    onClickButtonAdd?: () => void;
    onClickSearch: () => void;
    onClickReport: () => void;
}

export const ListingExtensionProjectToolbar: React.FC<IListingToolbarProps> = ({
    searchText = '',
    status = "",
    openForApplication = "",
    listCourseIds = "",
    onChangeSearchText,
    onChangeStatus,
    onChangeOpenForApplication,
    onChangeListCourseIds,
    textButton = 'Novo',
    showButton = true,
    isStudent = false,
    disableButtonReport = true,
    disableButtonSearch = false,
    disableButtonGenerateCertificate = false,
    onClickButtonAdd,
    onClickSearch,
    onClickReport
}) => {

    const [selectedCourseIds, setSelectedCourseIds] = useState<number[]>([]);
    const [allCourses, setAllCourses] = useState<ICourseResponse[]>([]);

    const xlDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);

    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const countRef = useRef(0);
    
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    useEffect(() => {

        if (process.env.NODE_ENV === 'development') {
            if (countRef.current === 0) {
                countRef.current = 1;
                return;
            }
        }

        handleGetAllCourses();

        if (listCourseIds) {
            setSelectedCourseIds(listCourseIds.split(',').map(v => parseInt(v)));
        }

    }, []);

    const handleCourseChange = (event: SelectChangeEvent<number[]>) => {
        const value = event.target.value;

        onChangeListCourseIds?.(Array.isArray(value) ? value.join(',') : value);

        setSelectedCourseIds(
            // Se for string, converte para array, caso contrário, usa o array de números
            typeof value === 'string' ? value.split(',').map(v => parseInt(v)) : value,
        );
    };

    const handleGetAllCourses = () => {

        if(isStudent)
            return;

        if (allCourses.length === 0) {

            CourseService.getAll()
                .then((result) => {

                    if (result instanceof Error) {

                        return;
                    }

                    setAllCourses(result);

                })
        }
    }

    return (
        <Box
            gap={1}
            marginX={1}
            padding={1}
            paddingX={2}
            display="flex"
            alignItems="center"
            component={Paper}
            flexWrap="wrap"
        >

            <TextField
                size="small"
                value={searchText}
                onChange={(e) => onChangeSearchText?.(e.target.value)}
                placeholder={Environment.SEARCH_INPUT}
            />

            <Conditional condition={!isStudent}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel>Status</InputLabel>
                    <Select
                        value={status}
                        label="Status"
                        onChange={(e) => onChangeStatus?.(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {statusEnum.map((status) => (
                            <MenuItem key={status.id} value={status.id}>
                                <ListItemText primary={status.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                    <InputLabel>Aberto para Inscrição</InputLabel>
                    <Select
                        value={openForApplication}
                        label="Aberto para Inscrição"
                        onChange={(e) => onChangeOpenForApplication?.(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>

                        <MenuItem key="true" value="true">
                            <ListItemText primary="Sim" />
                        </MenuItem>

                        <MenuItem key="false" value="false">
                            <ListItemText primary="Não" />
                        </MenuItem>
                    </Select>
                </FormControl>

                <FormControl sx={{ m: 1, width: 300 }} size="small">
                    <InputLabel>Curso</InputLabel>
                    <Select
                        multiple
                        value={selectedCourseIds}
                        onChange={handleCourseChange}
                        input={<OutlinedInput label="Curso" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={SelectProps}
                    >
                        {allCourses.map((course) => (
                            <MenuItem key={course.id} value={course.id}>
                                <Checkbox checked={selectedCourseIds.indexOf(course.id) > -1} />
                                <ListItemText primary={course.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </Conditional>

            {
                !xlDown
                &&
                (
                    <Box
                        flex={1}
                        gap={1}
                        display="flex" justifyContent="end"
                    >

                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<Icon>search</Icon>}
                            onClick={onClickSearch}
                            disabled={disableButtonSearch}
                            size="small"
                        >
                            Pesquisar
                        </Button>


                        <Conditional condition={!isStudent}>
                            <Button
                                variant="contained"
                                color="primary"
                                endIcon={<Icon>summarize</Icon>}
                                onClick={() => {
                                    handleCloseMenu();
                                    onClickReport();
                                }}
                                disabled={disableButtonReport}
                                size="small"
                                disableRipple
                            >
                                Gerar Relatório
                            </Button>
                        </Conditional>

                        <Conditional condition={showButton}>
                            <Button
                                variant="contained"
                                color="primary"
                                endIcon={<Icon>add</Icon>}
                                onClick={() => {
                                    handleCloseMenu();
                                    onClickButtonAdd?.();
                                }}
                                disabled={disableButtonGenerateCertificate}
                                size="small"
                                disableRipple
                            >
                                {textButton}
                            </Button>
                        </Conditional>
                    </Box>
                )
            }

            {
                xlDown
                &&
                (
                    <Box
                        flex={1}
                        gap={1}
                        display="flex" justifyContent="end"
                    >

                        <IconButton
                            color="primary"
                            size="small"
                            onClick={onClickSearch}
                        >
                            <Icon>search</Icon>
                        </IconButton>

                        <IconButton
                            id="demo-customized-button"
                            aria-controls={menuOpen ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={menuOpen ? 'true' : undefined}
                            onClick={handleClickMenu}
                            color="primary"
                        >
                            <KeyboardArrowDown />
                        </IconButton>

                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={handleCloseMenu}
                        >

                            <Conditional condition={!isStudent}>
                                <MenuItem
                                    disabled={disableButtonReport}
                                    onClick={() => {
                                        handleCloseMenu();
                                        onClickReport();
                                    }}
                                    disableRipple
                                    color="primary"
                                >
                                    <Icon>summarize</Icon>
                                    Gerar Relatório
                                </MenuItem>
                            </Conditional>

                            {
                                showButton &&
                                <MenuItem
                                    onClick={() => {
                                        handleCloseMenu();
                                        onClickButtonAdd?.();
                                    }}
                                    disableRipple
                                >
                                    <Icon>add</Icon>
                                    {textButton}
                                </MenuItem>
                            }

                        </StyledMenu>



                    </Box>
                )
            }


        </Box >
    );
}
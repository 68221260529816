import { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
    Icon, IconButton, LinearProgress, Pagination, Paper,
    Table, TableBody, TableCell, TableContainer,
    TableFooter,
    TableHead, TableRow, Tooltip
} from "@mui/material";

import { ExtensionProjectComputeHoursToolbar, ParticipationStatusChip, ConfirmDialog, Conditional } from "../../shared/components";
import { useAuthContext } from "../../shared/contexts";
import { Environment } from "../../shared/environment";
import { IStudentExtensionProjectSearchResponse } from "../../shared/interfaces";
import { BasePageLayout } from "../../shared/layouts";
import { UserService } from "../../shared/services/api";

export const ExtensionProjectComputeHours = () => {

    const [searchParam, setSearchParams] = useSearchParams();

    const { userId } = useAuthContext();

    const [usersList, setUsersList] = useState<IStudentExtensionProjectSearchResponse>();

    const [loading, setLoading] = useState<boolean>(false);
    const [rowCount, setRowCount] = useState<number>(0);
    const [userIdSelected, setUserIdSelected] = useState<number>(0);
    const [extensionProjectIdSelected, setExtensionProjectIdSelected] = useState<number>(0);

    const [dialogApproveOpen, setDialogApproveOpen] = useState<boolean>(false);

    const userName = useMemo(() => {
        return searchParam.get('userName') || '';
    }, [searchParam]);

    const registration = useMemo(() => {
        return searchParam.get('registration') || '';
    }, [searchParam]);

    const finalized = useMemo(() => {
        return searchParam.get('finalized') || "";
    }, [searchParam]);

    const listCourseId = useMemo(() => {
        return searchParam.get('listCourseId') || "";
    }, [searchParam]);

    const page = useMemo(() => {
        return Number(searchParam.get('page') || '1');
    }, [searchParam]);

    const countRef = useRef(0);

    useEffect(() => {

        if (process.env.NODE_ENV === 'development') {
            if (countRef.current === 0) {
                countRef.current = 1;
                return;
            }
        }

        handleSearch();
    }, [page]);

    const handleSearch = () => {

        setLoading(true);

        UserService.getStudendStatusPendingDeductionOfHours(
            userName,
            registration,
            finalized && finalized === "true" ? true : false,
            listCourseId,
            page - 1
        ).then((result) => {

            if (result instanceof Error) {
                return;
            }

            setUsersList(result);
            setRowCount(result.rowsCount);

        }).finally(() => {
            setLoading(false);
        });


    }

    const UpdateStatusToReportedWorkload = () => {

        setLoading(true);

        UserService.updateStatusToReportedWorkload({ userId: userIdSelected, extensionProjectId: extensionProjectIdSelected })
            .then((result) => {

                if (result instanceof Error) {
                    return;
                }

                handleSearch();
            })
            .finally(() => {
                setLoading(false);
            });

    }

    return (
        <BasePageLayout
            title="Usuários"
            toolbar={(
                <ExtensionProjectComputeHoursToolbar
                    userName={userName}
                    registration={registration}
                    finalized={finalized}
                    listCourseIds={listCourseId}
                    showButton
                    onChangeUserName={text => setSearchParams({
                        userName: text,
                        registration: registration,
                        finalized: finalized,
                        listCourseId: listCourseId,
                        page: page.toString()
                    }, { replace: true })}

                    onChangeRegistration={text => setSearchParams({
                        userName: userName,
                        registration: text,
                        finalized: finalized,
                        listCourseId: listCourseId,
                        page: page.toString()
                    }, { replace: true })}

                    onChangeFinalized={text => setSearchParams({
                        userName: userName,
                        registration: registration,
                        finalized: text,
                        listCourseId: listCourseId,
                        page: page.toString()
                    }, { replace: true })}

                    onChangeListCourseIds={cl => setSearchParams({
                        userName: userName,
                        registration: registration,
                        finalized: finalized,
                        listCourseId: cl,
                        page: page.toString()
                    }, { replace: true })}

                    onClickSearch={() => handleSearch()}
                />
            )}
        >
            <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
                {
                    loading &&
                    <LinearProgress variant='indeterminate' />
                }
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>Matrícula</TableCell>
                            <TableCell>Horas</TableCell>
                            <TableCell>Projeto</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersList?.items?.map(row => (
                            <TableRow key={`${row.userId}${row.extensionProjectId}`}>
                                <TableCell>{row.userId}</TableCell>
                                <TableCell>{row.user.name}</TableCell>
                                <TableCell>{row.user.registration}</TableCell>
                                <TableCell>{row.workload}</TableCell>
                                <TableCell>{row.extensionProject.name}</TableCell>
                                <TableCell>
                                    <ParticipationStatusChip status={row.status ?? 99} />
                                </TableCell>
                                <TableCell>
                                    <Conditional condition={row.status === 2}>
                                        <IconButton size="small"
                                            onClick={() => {
                                                setUserIdSelected(row.userId);
                                                setExtensionProjectIdSelected(row.extensionProjectId);
                                                setDialogApproveOpen(true);
                                            }}
                                        >
                                            <Tooltip title="Confirmar horas abatidas">
                                                <Icon>check</Icon>
                                            </Tooltip>
                                        </IconButton>
                                    </Conditional>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                    {rowCount === 0 && !loading && (
                        <caption>Nenhum registro encontrado</caption>
                    )}

                    <TableFooter>
                        {
                            rowCount > 0 &&
                            rowCount > Environment.ROWS_LIMIT10 &&
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Pagination
                                        page={page}
                                        count={Math.ceil(rowCount / Environment.ROWS_LIMIT10)}
                                        onChange={(_, newPage) => {
                                            setSearchParams({ userName: userName, registration: registration, finalized: finalized, page: newPage.toString() }, { replace: true })
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        }
                    </TableFooter>
                </Table>
            </TableContainer>


            <ConfirmDialog
                dialogTitle="Computar horas"
                dialogDescription="Deseja confirmar as horas deste usuário?"
                onClose={() => { setDialogApproveOpen(false) }}
                onConfirm={() => {
                    setDialogApproveOpen(false);
                    setLoading(true);
                    UpdateStatusToReportedWorkload();
                }}
                open={dialogApproveOpen}
            />
        </BasePageLayout>
    )
}
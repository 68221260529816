import { Box, Icon, Typography } from "@mui/material";
import { BasePageLayout } from "../../shared/layouts";

export const NotAuthorized = () => {

    return (
        <BasePageLayout title="Acesso negado">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%"
            >
                <Icon fontSize="large" color="error"  style={{ fontSize: 100 }} >block</Icon>
                <Typography variant="h4" gutterBottom align="center">
                    Acesso Negado
                </Typography>
                <Typography variant="body1" align="center">
                    Você não tem permissão para acessar esta página.
                </Typography>
            </Box>
        </BasePageLayout>
    );
}
import {
    Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemText,
    Typography, IconButton, Button, Divider, Icon, LinearProgress,
    Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel,
    Pagination,
    CardHeader,
    useMediaQuery,
    Theme,
    Chip
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import * as yup from 'yup';

import { ReportStatusChip, ConfirmDialog, Conditional } from '../../shared/components';
import { useAuthContext } from '../../shared/contexts';
import { useVForm, IVFormErrors, VForm, VTextField, VTextarea, InputFileUpload, VCheckbox } from '../../shared/forms';
import { IApprovalReportPut, IAddReportVersionPost, IReportVersionPost, IReportPost, IReportResponse, IReportSearchResponse, IDisapprovalReportPut } from '../../shared/interfaces';
import { ReportService, UserService } from '../../shared/services/api';
import { fileToBase64, formatDateString } from '../../shared/utils/Utils';

const aprovarReportSchema: yup.Schema<IApprovalReportPut> = yup.object().shape({
    reportId: yup.number().min(1).required(),
    approverId: yup.number().min(1).required(),
    comment: yup.string(),
    permanentlyDisapproved: yup.boolean().required()
});

const aprovarFinalReportReportSchema: yup.Schema<IApprovalReportPut> = yup.object().shape({
    reportId: yup.number().min(1).required(),
    approverId: yup.number().min(1).required(),
    workload: yup.number().min(1, 'A carga horária não pode ser menor que 1'),
    comment: yup.string(),
    permanentlyDisapproved: yup.boolean().required()
});

const disapprovalReportSchema: yup.Schema<IDisapprovalReportPut> = yup.object().shape({
    reportId: yup.number().min(1).required(),
    approverId: yup.number().min(1).required(),
    comment: yup.string().min(20).max(2056).required(),
    permanentlyDisapproved: yup.boolean().required()
});

const NewReportVersionSchema: yup.Schema<IAddReportVersionPost> = yup.object().shape({
    reportId: yup.number().required(),
    description: yup.string().min(20).max(500).required(),
    filePath: yup.mixed<File[]>()
        .required()
        .test('fileRequired', 'Por favor, selecione um arquivo', (value) => {
            return value !== undefined && value !== null && value.length > 0;
        })
});

const reportVersionSchema: yup.Schema<IReportVersionPost> = yup.object().shape({
    description: yup.string().min(20).max(500).required(),
    filePath: yup.mixed<File[]>()
        .required()
        .test('fileRequired', 'Por favor, selecione um arquivo', (value) => {
            return value !== undefined && value !== null && value.length > 0;
        })
});

const formAddReportValidationSchema: yup.Schema<IReportPost> = yup.object().shape({
    extensionProjectId: yup.number().required(),
    userId: yup.number().required(),
    isFinalReport: yup.boolean(),
    reportVersions: yup.array().of(reportVersionSchema).required().min(1),
});

interface ReportsListProps {
    extensionProjectId: number;
    extensionProjectResponsibleUserId?: number;
    userId?: number;
    status?: -1 | 0 | 1 | 2 | 3;
    approval?: boolean;
    isTeacher?: boolean;
    showReportForm?: boolean;
    title?: string;
}

const ReportsList: React.FC<ReportsListProps> = ({
    extensionProjectId,
    extensionProjectResponsibleUserId,
    userId = -1,
    status = -1,
    approval = false,
    isTeacher = false,
    showReportForm = false,
    title = "Relatórios  "
}) => {

    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const [expandedReport, setExpandedReport] = useState<null | IReportResponse>(null);
    const [reports, setReports] = useState<null | IReportSearchResponse>(null);
    const [rowCount, setRowCount] = useState<number>(0);
    const [page, setPage] = useState<number>(1);

    const [disapproveReportStatus, setDisapproveReportStatus] = useState<null | number>(null);
    const [reportApprovalId, setReportApprovalId] = useState<null | number>(null);
    const [reportIdNewVersion, setReportIdNewVersion] = useState<null | number>(null);
    const [isFinalReport, setIsFinalReport] = useState(false);
    const [reportDeleteId, setReportDeleteId] = useState<null | number>(null);

    const [loadingReportData, setLoadingReportData] = useState(false);
    const [loadingReportApproval, setLoadingReportApproval] = useState(false);

    const [dialogApprovalReportOpen, setDialogApprovalReportOpen] = useState(false);
    const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false);
    const [dialogNewReportVersionOpen, setDialogNewReportVersionOpen] = useState(false);
    const [reportFormDialogOpen, setReportFormDialogOpen] = useState(false);

    const handleToggleComments = (report: IReportResponse) => {
        if (expandedReport?.id === report.id) {
            setExpandedReport(null);
        } else {
            setExpandedReport(report);
        }
    };

    const countRef = useRef(0);

    useEffect(() => {

        if (process.env.NODE_ENV === 'development') {
            if (countRef.current === 0) {
                countRef.current = 1;
                return;
            }
        }

        getReports();
    }, [page]);

    const {
        formRef,
    } = useVForm();

    const approverId = useAuthContext().userId;

    const getReports = () => {

        setLoadingReportData(true);

        ReportService.search(extensionProjectId, userId, status, page - 1)
            .then((result) => {

                if (result instanceof Error) {
                    return;
                }

                setReports(result);
                setRowCount(result.rowsCount);
            })
            .finally(() => {
                setLoadingReportData(false);
            });
    }

    const GetSuggestedWorkload = (userId: number) => {

        setLoadingReportApproval(true);

        UserService.GetSuggestedWorkload(userId, extensionProjectId)
            .then((result) => {

                if (result instanceof Error) {
                    return;
                }

                formRef?.current?.setFieldValue('workload', result);

            })
            .finally(() => {
                setLoadingReportApproval(false);
            });
    }

    const getReportAttachment = (reportId: number) => {

        setLoadingReportData(true);

        ReportService.getAtachment(reportId)
            .then((result) => {

                if (result instanceof Error) {

                    enqueueSnackbar(
                        <Typography >
                            Falha ao baixar o anexo do relatório
                        </Typography>,
                        { variant: 'error' });
                }

            })
            .finally(() => {
                setLoadingReportData(false);
            });
    }

    const handleApprovalReport = async (formData: IApprovalReportPut | IDisapprovalReportPut) => {
        // Adiciona os valores padrão ao formData
        formData = {
            ...formData,
            reportId: reportApprovalId ?? 0,
            approverId: Number(approverId),
            workload: Number(formData.workload) ?? 0,
            comment: formData.comment ?? ''
        };

        let finalData;

        if (disapproveReportStatus === 1) {
            // Cria um objeto do tipo IApprovalReportPut
            const approvalData: IApprovalReportPut = {
                ...formData,
                permanentlyDisapproved: false,
            };

            finalData = approvalData;

            try {
                console.log("Iniciando validação de aprovação");

                const validatedData =
                    isFinalReport ?
                        await aprovarFinalReportReportSchema.validate(approvalData, { abortEarly: false })
                        :
                        await aprovarReportSchema.validate(approvalData, { abortEarly: false });

                console.log("Validação de aprovação bem-sucedida", validatedData);
                setLoadingReportApproval(true);

                const result = await ReportService.ApproveReport(validatedData);
                console.log("Resultado da aprovação", result);

                if (!(result instanceof Error)) {
                    enqueueSnackbar(
                        <Typography>
                            Relatório aprovado com sucesso.
                        </Typography>,
                        { variant: 'success' }
                    );

                    setDialogApprovalReportOpen(false);
                    getReports();
                }
            } catch (errors) {
                console.error("Erro na validação de aprovação", errors);
                const validationErrors: IVFormErrors = {};
                if (errors instanceof yup.ValidationError) {
                    errors.inner.forEach(error => {
                        if (error.path) {
                            validationErrors[error.path] = error.message;
                        }
                    });
                }
                formRef.current?.setErrors(validationErrors);
            } finally {
                setLoadingReportApproval(false);
            }

        } else {
            // Cria um objeto do tipo IDisapprovalReportPut
            const disapprovalData: IDisapprovalReportPut = {
                ...formData,
                permanentlyDisapproved: disapproveReportStatus === 3,
            };

            finalData = disapprovalData;

            try {
                console.log("Iniciando validação de reprovação");
                const validatedData = await disapprovalReportSchema.validate(disapprovalData, { abortEarly: false });

                console.log("Validação de reprovação bem-sucedida", validatedData);
                setLoadingReportApproval(true);

                const result = await ReportService.DisapproveReport(validatedData);
                console.log("Resultado da reprovação", result);

                if (!(result instanceof Error)) {
                    enqueueSnackbar(
                        <Typography>
                            Relatório reprovado com sucesso.
                        </Typography>,
                        { variant: 'success' }
                    );

                    setDialogApprovalReportOpen(false);
                    getReports();
                }
            } catch (errors) {
                console.error("Erro na validação de reprovação", errors);
                const validationErrors: IVFormErrors = {};
                if (errors instanceof yup.ValidationError) {
                    errors.inner.forEach(error => {
                        if (error.path) {
                            validationErrors[error.path] = error.message;
                        }
                    });
                }
                formRef.current?.setErrors(validationErrors);
            } finally {
                setLoadingReportApproval(false);
            }
        }

        console.log("finalData", finalData);
    };

    const handleSaveReportVersion = async (formData: IAddReportVersionPost) => {

        formData.reportId = reportIdNewVersion ?? 0;

        NewReportVersionSchema
            .validate(formData, { abortEarly: false })
            .then(async (validateDatas) => {

                const { filePath, ...restData } = validateDatas;

                const base64String = await fileToBase64(filePath[0]);

                validateDatas = {
                    ...restData,
                    file: base64String,
                    filePath: filePath
                };

                setLoadingReportApproval(true);

                ReportService.createNewReportVersion(validateDatas)
                    .then((result) => {

                        if (result instanceof Error) {
                            return;
                        }

                        enqueueSnackbar(
                            <Typography>
                                Nova versão cadastrada com sucesso.
                            </Typography>,
                            { variant: 'success' });

                        setDialogNewReportVersionOpen(false);
                        getReports();

                    })
                    .finally(() => {
                        setLoadingReportApproval(false);
                    });

            })
            .catch((errors: yup.ValidationError) => {

                const validationErrors: IVFormErrors = {}

                errors?.inner?.forEach(error => {

                    if (!error.path) return;

                    validationErrors[error.path] = error.message;
                });

                formRef.current?.setErrors(validationErrors);
            });

    };

    const deleteReport = () => {

        setLoadingReportData(true);

        ReportService.DeleteReport(reportDeleteId ?? 0)
            .then((result) => {

                if (result instanceof Error) {

                    enqueueSnackbar(
                        <Typography >
                            Falha ao excluir o relatório
                        </Typography>,
                        { variant: 'error' });
                }

                enqueueSnackbar(
                    <Typography >
                        Relatório excluído com sucesso
                    </Typography>,
                    { variant: 'success' });

                getReports();
            })
            .finally(() => {
                setLoadingReportData(false);
            });
    }

    const handleSaveReport = async (formData: IReportPost) => {

        formData.extensionProjectId = extensionProjectId ?? 0;
        formData.userId = Number(userId);

        formAddReportValidationSchema
            .validate(formData, { abortEarly: false })
            .then(async (validateDatas) => {

                const { filePath, ...restData } = validateDatas.reportVersions[0];

                const base64String = await fileToBase64(filePath[0]);

                validateDatas.reportVersions[0] = {
                    ...restData,
                    file: base64String,
                    filePath: filePath
                };

                setLoadingReportApproval(true);

                ReportService.create(validateDatas)
                    .then((result) => {

                        if (result instanceof Error) {
                            return;
                        }

                        enqueueSnackbar(
                            <Typography>
                                Relatório cadastrado com sucesso.
                            </Typography>,
                            { variant: 'success' });

                        setReportFormDialogOpen(false);
                        getReports();
                    })
                    .finally(() => {
                        setLoadingReportApproval(false);
                    });

            })
            .catch((errors: yup.ValidationError) => {

                const validationErrors: IVFormErrors = {}

                errors?.inner?.forEach(error => {

                    if (!error.path) return;

                    validationErrors[error.path] = error.message;
                });

                formRef.current?.setErrors(validationErrors);
            });

    };

    return (
        <Box>

            <Conditional condition={loadingReportData}>
                <LinearProgress variant='indeterminate' />
            </Conditional>

            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" flexWrap="wrap">
                    <Box display="flex" flexWrap="wrap">
                        <CardHeader

                            title={
                                <>
                                    {title}
                                    <Conditional condition={showReportForm}>

                                        <Conditional condition={smDown}>
                                            <Tooltip title={"Adicionar Relatório"}>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => setReportFormDialogOpen(true)}
                                                >
                                                    <Icon>add</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </Conditional>

                                        <Conditional condition={!smDown}>
                                            <Button
                                                variant="contained"
                                                onClick={() => setReportFormDialogOpen(true)}
                                                startIcon={<Icon>add</Icon>}
                                                size='small'
                                            >
                                                Adicionar

                                            </Button>
                                        </Conditional>

                                        <Dialog
                                            open={reportFormDialogOpen}
                                            onClose={() => setReportFormDialogOpen(false)}
                                            PaperProps={{
                                                component: 'form',
                                            }}
                                            fullWidth
                                        >
                                            <Conditional condition={loadingReportApproval}>
                                                <LinearProgress variant="indeterminate" />
                                            </Conditional>
                                            <VForm ref={formRef} onSubmit={handleSaveReport}>
                                                <DialogTitle>Adicionar relatório</DialogTitle>
                                                <DialogContent>
                                                    <Grid container direction="column">
                                                        <VTextField
                                                            name="extensionProjectId"
                                                            defaultValue={extensionProjectId ?? 0}
                                                            style={{ display: 'none' }}
                                                            type="number"
                                                        />
                                                        <VTextField
                                                            defaultValue={userId}
                                                            name="userId"
                                                            style={{ display: 'none' }}
                                                            type="number"
                                                        />

                                                        <Grid container item direction="row" spacing={2}>
                                                            <Grid item
                                                                xs={11}
                                                                md={11}
                                                                lg={11}
                                                                xl={11}
                                                            >
                                                                <VTextarea
                                                                    placeholder="Descrição (resumo do seu relatório)"
                                                                    name="reportVersions[0].description"
                                                                    required
                                                                    maxLength={1000}
                                                                    minRows={6}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item >

                                                        </Grid>
                                                        <Grid item>
                                                            <InputFileUpload
                                                                disabled={loadingReportApproval}
                                                                buttonLabel="Arquivo relatório"
                                                                name="reportVersions[0].filePath"
                                                                accept=".pdf"
                                                            />
                                                        </Grid>
                                                        <Grid item>

                                                            {!isTeacher && (

                                                                <FormControlLabel
                                                                    control={<VCheckbox name="isFinalReport" />}
                                                                    label="Relatório Final?"
                                                                />

                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button
                                                        disabled={loadingReportApproval}
                                                        onClick={() => setReportFormDialogOpen(false)}
                                                        variant="contained"
                                                    >
                                                        Cancelar
                                                    </Button>
                                                    <Button
                                                        disabled={loadingReportApproval}
                                                        onClick={() => formRef.current?.submitForm()}
                                                        variant="contained"
                                                    >
                                                        Adicionar
                                                    </Button>
                                                </DialogActions>
                                            </VForm>
                                        </Dialog>
                                    </Conditional>
                                </>
                            }
                        />
                    </Box>
                </Box>

                <Box display="flex" alignItems="center" flexWrap="nowrap" whiteSpace="nowrap">
                    {rowCount > 0 && rowCount > 5 && (
                        <Pagination
                            page={page}
                            count={Math.ceil(rowCount / 5)}
                            onChange={(_, newPage) => {
                                setPage(newPage);
                            }}
                            shape="rounded"
                            variant="outlined"
                            size="small"
                            siblingCount={0}
                            boundaryCount={1}
                            sx={{ paddingRight: 1 }}
                        />
                    )}
                </Box>
            </Box>


            <List sx={{ paddingTop: 0 }}>

                {(!reports?.items || reports?.items?.length === 0) && (
                    <ListItem>
                        <Typography>
                            Nenhum relatório encontrado
                        </Typography>
                    </ListItem>
                )}

                {reports?.items?.sort((a, b) => {
                    if (status === 0) {
                        return a.id - b.id; // Ordena pelo id se o status for 0
                    }
                    return 0; // Mantém a ordem original se o status não for 0
                }).map((report) => (
                    <>
                        <ListItem alignItems="flex-start" sx={{ paddingTop: 0 }}>
                            <ListItemText
                                primary={
                                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                                        <Box>
                                            {report.id} - {report.user.name}

                                            <Conditional condition={report.user.email.includes("aluno")}>

                                                <Chip
                                                    icon={
                                                        <Icon>
                                                            {report.isFinalReport ? 'check_circle' : 'hourglass_empty'}
                                                        </Icon>
                                                    }
                                                    label={report.isFinalReport ? 'Relatório Final' : 'Relatório Parcial'}
                                                    color={report.isFinalReport ? 'success' : 'warning'}
                                                    sx={{ marginLeft: 1 }}
                                                />

                                            </Conditional>

                                        </Box>

                                        <Box display="flex" alignItems="center" flexWrap="wrap">

                                            <Conditional condition={approval}>

                                                <Tooltip title="Aprovar relatório">

                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            setDialogApprovalReportOpen(true);
                                                            setDisapproveReportStatus(1);
                                                            setReportApprovalId(report.id);
                                                            setIsFinalReport(report.isFinalReport);
                                                            if (report.isFinalReport) {
                                                                GetSuggestedWorkload(report.userId);
                                                            }
                                                        }}
                                                        color="success"
                                                    >
                                                        <Icon>check_circle</Icon>
                                                    </IconButton>

                                                </Tooltip>

                                                <Tooltip title="Reprovar relatório para revisão">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            setDialogApprovalReportOpen(true);
                                                            setDisapproveReportStatus(2);
                                                            setReportApprovalId(report.id);
                                                            setIsFinalReport(false);
                                                        }}
                                                        color="warning"
                                                    >
                                                        <Icon>do_not_disturb_alt</Icon>
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title="Reprovar relatório">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            setDialogApprovalReportOpen(true);
                                                            setDisapproveReportStatus(3);
                                                            setReportApprovalId(report.id);
                                                            setIsFinalReport(false);
                                                        }}
                                                        color="error"
                                                    >
                                                        <Icon>cancel</Icon>
                                                    </IconButton>
                                                </Tooltip>

                                            </Conditional>

                                            <Conditional condition={report.status === 2}>
                                                <Tooltip title="Adicionar nova versão">
                                                    <IconButton
                                                        onClick={() => {
                                                            setDialogNewReportVersionOpen(true);
                                                            setReportIdNewVersion(report.id);
                                                        }}
                                                        disabled={loadingReportData}
                                                    >
                                                        <Icon>post_add</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </Conditional>

                                            <Tooltip title="Download do anexo">
                                                <IconButton
                                                    onClick={() => getReportAttachment(report.id)}
                                                    disabled={loadingReportData}
                                                >
                                                    <Icon>download</Icon>
                                                </IconButton>
                                            </Tooltip>

                                            <Conditional
                                                condition={
                                                    report?.status === 0
                                                    && Number(report?.userId) === Number(userId)
                                                    && report.reportVersions[0].versionNumber === 1
                                                }
                                            >

                                                <Tooltip title="Excluir Relatório">

                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            setReportDeleteId(report.id);
                                                            setDialogDeleteOpen(true);
                                                        }}
                                                        color="error"
                                                    >
                                                        <Icon>delete</Icon>
                                                    </IconButton>

                                                </Tooltip>

                                            </Conditional>

                                            <ReportStatusChip status={report.status} />
                                        </Box>
                                    </Box>
                                }
                                secondary={
                                    <>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Box>
                                                {`${formatDateString(report.reportVersions[0]?.createdAt)}`}
                                                <br />
                                                <Typography sx={{ textAlign: 'justify', fontSize: '0.83rem' }}>
                                                    {`${report.reportVersions[0]?.description}`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {(!report.reportVersions[0].reportComments || report.reportVersions[0].reportComments?.length > 0) && (
                                            <Typography key={report.id} variant="body2" color="text.secondary" sx={{ marginTop: 1 }}>
                                                Comentários ({report.reportVersions[0].reportComments?.length})

                                                <Conditional condition={report?.reportVersions[0]?.reportComments?.length > 0}>
                                                    <Tooltip title="Visualizar comentários">
                                                        <IconButton onClick={() => handleToggleComments(report)}>
                                                            {
                                                                expandedReport?.id === report.id ? <ExpandLess /> : <ExpandMore />
                                                            }
                                                        </IconButton>
                                                    </Tooltip>
                                                </Conditional>
                                            </Typography>

                                        )}


                                        {expandedReport?.id === report.id && (
                                            <Box>

                                                {(!report.reportVersions[0].reportComments || report.reportVersions[0].reportComments?.length === 0) && (
                                                    <Typography key={report.id} variant="body2" color="text.secondary">
                                                        Nenhum comentário encontrado
                                                    </Typography>
                                                )}



                                                {report.reportVersions[0].reportComments?.filter(rp => rp.reportVersionId === report.reportVersions[0].id).map((comment, index) => (
                                                    <Typography key={index} variant="body2" color="text.secondary">
                                                        <b>{`${comment.user.name}: `}</b> {comment.description}
                                                    </Typography>
                                                ))}
                                            </Box>
                                        )}
                                    </>
                                }
                            />

                        </ListItem>
                        <Divider />
                    </>
                ))}
            </List>

            <Conditional condition={approval}>
                <Dialog
                    open={dialogApprovalReportOpen}
                    onClose={() => setDialogApprovalReportOpen(false)}
                    PaperProps={{
                        component: 'form'
                    }}
                    fullWidth
                >
                    <Conditional condition={loadingReportApproval}>
                        <LinearProgress variant='indeterminate' />
                    </Conditional>

                    <VForm ref={formRef} onSubmit={handleApprovalReport}>

                        <DialogTitle>
                            <Conditional condition={disapproveReportStatus === 1}>
                                Aprovar relatório
                                <Conditional condition={isFinalReport}>
                                    {" "} final
                                </Conditional>
                            </Conditional>
                            <Conditional condition={disapproveReportStatus === 2}>
                                Reprovar relatório para revisão
                            </Conditional>
                            <Conditional condition={disapproveReportStatus === 3}>
                                Reprovar relatório
                            </Conditional>
                            {` ID ${reportApprovalId}`}
                        </DialogTitle>

                        <DialogContent>
                            <Grid container direction="column" padding={1} spacing={2}>

                                <Grid item>
                                    <VTextField
                                        label="Carga horária de trabalho total em horas do aluno neste projeto (Números inteiros)"
                                        name="workload"
                                        type="number"
                                        disabled={loadingReportApproval}
                                        fullWidth
                                        required
                                        style={{ display: isFinalReport ? 'block' : 'none' }}
                                        inputProps={{ min: 1 }}
                                    />
                                </Grid>

                                <Grid item>
                                    <VTextarea
                                        placeholder={disapproveReportStatus === 1 ? "Comentário (Opcional)" : "Motivo da reprovação (Obrigatório)"}
                                        name="comment"
                                        maxLength={2056}
                                        required
                                        minRows={5}
                                    />
                                </Grid>

                            </Grid>

                        </DialogContent>

                        <DialogActions>
                            <Button
                                disabled={loadingReportApproval}
                                onClick={() => setDialogApprovalReportOpen(false)}
                            >
                                Cancelar
                            </Button>
                            <Button
                                disabled={loadingReportApproval}
                                onClick={() => formRef.current?.submitForm()}
                            >
                                Confirmar
                            </Button>
                        </DialogActions>

                    </VForm>
                </Dialog>
            </Conditional>

            <Dialog
                open={dialogNewReportVersionOpen}
                onClose={() => setDialogNewReportVersionOpen(false)}
                PaperProps={{
                    component: 'form'
                }}
                fullWidth
            >
                <Conditional condition={loadingReportApproval}>
                    <LinearProgress variant='indeterminate' />
                </Conditional>
                <VForm ref={formRef} onSubmit={handleSaveReportVersion}>

                    <DialogTitle>Adicionar nova versão</DialogTitle>

                    <DialogContent>
                        <Grid container direction="column" padding={1} spacing={2}>

                            <VTextField
                                name="reportId"
                                defaultValue={reportIdNewVersion}
                                style={{ display: 'none' }}
                                type="number"
                            />

                            <Grid item>
                                <VTextarea
                                    placeholder="Descrição (resumo do seu relatório)"
                                    name="description"
                                    required
                                    minRows={6}
                                    maxLength={1000}
                                />
                            </Grid>

                            <Grid item>
                                <InputFileUpload
                                    buttonLabel="Arquivo relatório"
                                    name="filePath"
                                    accept=".pdf"
                                />
                            </Grid>

                        </Grid>

                    </DialogContent>

                    <DialogActions>
                        <Button
                            disabled={loadingReportApproval}
                            onClick={() => setDialogNewReportVersionOpen(false)}
                        >
                            Cancelar
                        </Button>

                        <Button
                            disabled={loadingReportApproval}
                            onClick={() => formRef.current?.submitForm()}
                        >
                            Adicionar
                        </Button>

                    </DialogActions>


                </VForm>
            </Dialog>

            <ConfirmDialog
                dialogTitle="Excluir Relatório"
                dialogDescription="Deseja excluir este relatório?"
                onClose={() => { setDialogDeleteOpen(false) }}
                onConfirm={() => {
                    setDialogDeleteOpen(false)
                    deleteReport();
                }}
                open={dialogDeleteOpen}
            />
        </Box>
    );
};

export default ReportsList;

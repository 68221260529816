import { Environment } from "../../../environment";
import {
    IReportSearchResponse, IReportPost, IReportResponse,
    IApprovalReportPut, IAddReportVersionPost
} from "../../../interfaces";
import { Api } from "../axios-config";


const controller = "Report"

const search = async (extensionProjectId: number, userId: number, status: -1 | 0 | 1 | 2 | 3,  page: number): Promise<IReportSearchResponse | Error> => {

    try {

        const { data } = await Api.get<IReportSearchResponse>(`${controller}?ExtensionProjectId=${extensionProjectId}&UserId=${userId}&Status=${status}&RowsPerPage=${5}&Page=${page}`);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar os relatórios.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar os relatórios.");
    }
}


const create = async (report: IReportPost): Promise<IReportResponse | Error> => {

    try {

        const { data } = await Api.post<IReportResponse>(`${controller}`, report);

        if (data) {
            return data;
        }

        return new Error("Erro ao criar relatório.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao criar projeto de extensão.");
    }
}

const ApproveReport = async (approvalReport: IApprovalReportPut): Promise<any | Error> => {

    try {

        await Api.put<null>(`${controller}/ApproveReport`, approvalReport);

        return null;

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao approvar o relatório.");
    }
}

const DisapproveReport = async (approvalReport: IApprovalReportPut): Promise<any | Error> => {

    try {

        await Api.put<null>(`${controller}/DisapproveReport`, approvalReport);

        return null;

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao reprovar o relatório.");
    }
}

const getAtachment = async (reportId: number): Promise<any | Error> => {

    try {

        var response = await Api.get<File>(`${controller}/GetAttachment/${reportId}`, {
            responseType: 'blob',
            headers: {
                'Accept': 'application/pdf'

            }
        });

        const contentDisposition = response.headers['content-disposition'];
        const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);

        var fileName = match ? decodeURIComponent(match[1].replace(/\+/g, ' ')) : 'report.pdf';

        fileName = fileName.replace(/^"(.*)"$/, '$1');

        const blob = new Blob([response.data], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);

        return null;

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao fazer download do relatório.");
    }
}

const createNewReportVersion = async (report: IAddReportVersionPost): Promise<IReportResponse | Error> => {

    try {

        const { data } = await Api.post<IReportResponse>(`${controller}/CreateNewReportVersion`, report);

        if (data) {
            return data;
        }

        return new Error("Erro ao criar nova versão do relatório.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao criar nova versão do relatório.");
    }
}

const DeleteReport = async (reportId: number): Promise<any | Error> => {

    try {

        await Api.delete(`${controller}/DeleteReport/${reportId}`);

        return null;

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao deletar relatório.");
    }
}


export const ReportService = {
    search,
    create,
    ApproveReport,
    DisapproveReport,
    getAtachment,
    createNewReportVersion,
    DeleteReport
};
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Box, Button, FormControlLabel, Grid, Icon, LinearProgress, Paper, Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import * as yup from 'yup';
import dayjs from "dayjs";

import { Conditional, DetailToolbar } from "../../shared/components";
import { useAuthContext } from "../../shared/contexts";
import { useVForm, IVFormErrors, VForm, VTextField, InputFileUpload, VSelect, VDatePicker, VTextarea, VCheckbox } from "../../shared/forms";
import { ICourse_ExtensionProjectPost, IExtensionProjectPost, IExtensionProjectPut, IExtensionProjectResponse, ICourseResponse } from "../../shared/interfaces";
import { BasePageLayout } from "../../shared/layouts";
import { CourseService, ExtensionProjectService } from "../../shared/services/api";
import { fileToBase64 } from "../../shared/utils/Utils";

const course_ExtensionProjectsSchema: yup.Schema<ICourse_ExtensionProjectPost> = yup.object().shape({
    courseId: yup.number().required()
});

const formValidationSchema: yup.Schema<IExtensionProjectPost> = yup.object().shape({
    responsibleUserId: yup.number().required(),
    name: yup.string().required().min(5).max(150),
    startDateAux: yup.date().min(new Date(new Date().setDate(new Date().getDate() - 1)), 'A data de início deve ser igual ou posterior a hoje').required(),
    endDateAux: yup.date().min(yup.ref('startDateAux'), "A data deve ser maior que a data de início").required(),
    description: yup.string().min(20).max(500).required(),
    filePath: yup.mixed<File[]>()
        .required()
        .test('fileRequired', 'Por favor, selecione um arquivo', (value) => {
            return value !== undefined && value !== null && value.length > 0;
        }),
    maximumNumberParticipants: yup.number().required().min(0),
    dailyWorkload: yup.number().required().min(0),
    course_ExtensionProjects: yup.array().of(course_ExtensionProjectsSchema).required().min(1),
    isPublic: yup.boolean(),
    publicAddress: yup.string().max(256).when('isPublic', {
        is: true,
        then: schema => schema.required('O endereço público é obrigatório quando o projeto é público.'),
        otherwise: schema => schema.notRequired()
    }),
    publicContactDescription: yup.string().max(256).when('isPublic', {
        is: true,
        then: schema => schema.required('A descrição de contato público é obrigatória quando o projeto é público.'),
        otherwise: schema => schema.notRequired()
    })
});

const formUpdateValidationSchema: yup.Schema<IExtensionProjectPut> = yup.object().shape({
    id: yup.number().required(),
    responsibleUserId: yup.number().required(),
    name: yup.string().required().min(5).max(150),
    startDateAux: yup.date().required(),
    endDateAux: yup.date().min(yup.ref('startDateAux'), "A data deve ser maior que a data de início").required(),
    description: yup.string().min(20).max(500).required(),
    maximumNumberParticipants: yup.number().required().min(0),
    dailyWorkload: yup.number().required().min(0),
    isPublic: yup.boolean(),
    publicAddress: yup.string().max(256).when('isPublic', {
        is: true,
        then: schema => schema.required('O endereço público é obrigatório quando o projeto é público.'),
        otherwise: schema => schema.notRequired()
    }),
    publicContactDescription: yup.string().max(256).when('isPublic', {
        is: true,
        then: schema => schema.required('A descrição de contato público é obrigatória quando o projeto é público.'),
        otherwise: schema => schema.notRequired()
    })
});

export const ExtensionProjectsForm: React.FC = () => {

    const { id } = useParams<'id'>();
    const { userId } = useAuthContext();
    const {
        formRef, save, saveAndBack, isSaveAndBack
    } = useVForm();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [extensionProject, setExtensionProject] = useState<IExtensionProjectResponse>();
    const [allCourses, setAllCourses] = useState<ICourseResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isPublic, setIsPublic] = useState<boolean>(false);

    const publicAddressRef = useRef<HTMLInputElement>(null);

    const countRef = useRef(0);

    useEffect(() => {

        if (process.env.NODE_ENV === 'development') {
            if (countRef.current === 0) {
                countRef.current = 1;
                return;
            }
        }

        if (Number(id) === 0) {
            formRef.current?.setData({
                name: '',
                course_ExtensionProjects: undefined,
                maximumNumberParticipants: undefined,
                startDate: undefined,
                endDate: undefined,
                description: undefined,
                isPublic: undefined,
                publicName: undefined,
                publicDescription: undefined,
                publicAddress: undefined,
                publicContactDescription: undefined,
            });
        }
        else {
            handleGetExtensionProject(Number(id));
        }

        handleGetAllCourses();

    }, [formRef]);


    useEffect(() => {

        if (isPublic)
            publicAddressRef.current?.scrollIntoView({ behavior: 'smooth' });

    }, [formRef, isPublic, publicAddressRef]);



    const handleGetAllCourses = () => {

        if (allCourses.length === 0) {

            CourseService.getAll()
                .then((result) => {

                    if (result instanceof Error) {
                        return;
                    }

                    setAllCourses(result);

                })
        }
    }

    const handleGetExtensionProject = (id: number) => {

        if (id === 0) {
            enqueueSnackbar(
                <Typography >
                    Projeto não encontrado
                </Typography>,
                { variant: 'error' });

            navigate("/extension-projects")
        }

        setLoading(true);

        ExtensionProjectService.getById(Number(id))
            .then((result) => {

                if (result instanceof Error) {
                    navigate('/extension-projects');
                } else {

                    if (!result) {
                        enqueueSnackbar(
                            <Typography >
                                Projeto de extensão não encontrado
                            </Typography>,
                            { variant: 'error' });

                        navigate("/extension-projects")
                    }



                    if (
                        !(result.status === 0 || result.status === 2)
                        &&
                        (
                            result?.course_ExtensionProjects
                            &&
                            result?.course_ExtensionProjects?.filter((cep) => cep.status === 2).length === 0
                        )
                    ) {
                        navigate(`/extension-projects/${id}`, { replace: true });
                    }


                    formRef.current?.setData({
                        name: result.name,
                        maximumNumberParticipants: result.maximumNumberParticipants,
                        startDateAux: dayjs(result.startDate),
                        endDateAux: dayjs(result.endDate),
                        dailyWorkload: result.dailyWorkload,
                        description: result.description,
                        isPublic: result.isPublic,
                        publicAddress: result.publicAddress,
                        publicContactDescription: result.publicContactDescription,
                    });

                    setExtensionProject(result);
                    setIsPublic(result.isPublic ?? false);

                }
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handleValidationErrors = (errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        let firstInvalidField: string | null = null;

        // Captura os erros e identifica o primeiro campo inválido
        errors.inner.forEach(error => {
            if (!error.path) return;

            validationErrors[error.path] = error.message;

            if (!firstInvalidField) {
                firstInvalidField = error.path; // Armazena o nome do primeiro campo inválido
            }
        });

        formRef.current?.setErrors(validationErrors);

        // Rolar para o primeiro campo inválido
        if (firstInvalidField) {
            // Garante que firstInvalidField é uma string antes de tentar usar replace
            const formattedFieldName = (firstInvalidField as string).replace(/\./g, '\\.');
            const errorFieldElement = document.querySelector(`[name="${formattedFieldName}"]`);

            if (errorFieldElement) {
                // Foca no campo para garantir que ele esteja em vista
                (errorFieldElement as HTMLElement).focus();

                // Usar scrollIntoView para garantir que o campo esteja visível
                (errorFieldElement as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    };

    const handleCreateExtensionProject = async (formData: IExtensionProjectPost) => {

        formData.course_ExtensionProjects = formData.course_ExtensionProjects.map(
            (id: any) => ({ courseId: id })
        );

        formData.responsibleUserId = userId;

        if (formData.maximumNumberParticipants === undefined) {
            formData.maximumNumberParticipants = 0;
        }

        formValidationSchema
            .validate(formData, { abortEarly: false })
            .then(async (validateDatas) => {

                const { filePath, ...restData } = validateDatas;

                const base64String = await fileToBase64(filePath[0]);

                validateDatas = {
                    ...restData,
                    startDate: restData.startDateAux?.toISOString().split('T')[0],
                    endDate: restData.endDateAux?.toISOString().split('T')[0],
                    file: base64String,
                    filePath: filePath
                };

                setLoading(true);

                ExtensionProjectService.create(validateDatas)
                    .then((result) => {

                        if (result instanceof Error) {
                            return;
                        }

                        enqueueSnackbar(
                            <Typography>
                                Projeto de extensão cadastrado com sucesso.
                            </Typography>,
                            { variant: 'success' });

                        if (isSaveAndBack()) {
                            navigate("/extension-projects/search");
                            return;
                        }

                        navigate(`/extension-projects/${result.id}`);

                    })
                    .finally(() => {
                        setLoading(false);
                    });

            })
            .catch((errors: yup.ValidationError) => {
                handleValidationErrors(errors);
            });

    }

    const handleUpdateExtensionProject = async (formData: IExtensionProjectPut) => {

        formData.id = Number(id);

        formData.responsibleUserId = userId;

        if (formData.maximumNumberParticipants === undefined) {
            formData.maximumNumberParticipants = 0;
        }

        formUpdateValidationSchema
            .validate(formData, { abortEarly: false })
            .then(async (validateDatas) => {

                const { filePath, ...restData } = validateDatas;
                var base64String = undefined;
                if(filePath && filePath.length > 0){
                    base64String = await fileToBase64(filePath[0]);
                }

                validateDatas = {
                    ...restData,
                    startDate: restData.startDateAux?.toISOString().split('T')[0],
                    endDate: restData.endDateAux?.toISOString().split('T')[0],
                    file: base64String ?? undefined,
                    filePath: filePath
                };

                setLoading(true);

                ExtensionProjectService.update(validateDatas)
                    .then((result) => {

                        if (result instanceof Error) {
                            return;
                        }

                        enqueueSnackbar(
                            <Typography>
                                Projeto de extensão atualizado com sucesso.
                            </Typography>,
                            { variant: 'success' });

                        if (isSaveAndBack()) {
                            navigate("/extension-projects/search");
                            return;
                        }

                        navigate(`/extension-projects/${result.id}`);

                    })
                    .finally(() => {
                        setLoading(false);
                    });

            })
            .catch((errors: yup.ValidationError) => {
                handleValidationErrors(errors);
            });

    }

    const handleSubmit = async (formData: IExtensionProjectPost | IExtensionProjectPut) => {

        if (Number(id) === 0) {
            handleCreateExtensionProject(formData as IExtensionProjectPost);
        }
        else {
            handleUpdateExtensionProject(formData as IExtensionProjectPut);
        }

    };

    return (
        <BasePageLayout
            title={Number(id) === 0 ? "Novo Projeto de Extensão" : `Editar Projeto de Extensão ${extensionProject?.id}`}
            toolbar={
                <DetailToolbar
                    showButtonDelete={false}
                    showButtonAdd={false}
                    showButtonSaveAndBack
                    showButtonSaveLoading={loading}
                    showButtonSaveAndBackLoading={loading}
                    showButtonBackLoading={loading}

                    onClickButtonSave={save}
                    onClickButtonSaveAndBack={saveAndBack}
                    onClickButtonBack={() => {
                        if (Number(id) === 0) {
                            navigate("/extension-projects/search", { replace: true })
                        }
                        else {
                            navigate(`/extension-projects/${id}`, { replace: true })
                        }
                    }}
                />
            }
        >

            <VForm ref={formRef} onSubmit={handleSubmit}>
                <Box margin={1} display="flex" flexDirection="column" component={Paper}>


                    <Grid container direction="column" padding={1} spacing={2}>

                        {
                            loading &&
                            <Grid item>
                                <LinearProgress variant='indeterminate' />
                            </Grid>
                        }
                        <Grid item>
                            <Typography variant="h6">Dados do projeto</Typography>
                        </Grid>

                        <Grid container item direction="row" spacing={2}>
                            <Grid item
                                xs={12}
                                md={8}
                                lg={6}
                                xl={3}
                            >
                                <InputFileUpload
                                    buttonLabel="Arquivo Proposta (PDF) *"
                                    name="filePath"
                                    accept=".pdf"
                                />
                            </Grid>
                        </Grid>

                        <Grid container item direction="row" spacing={2}>
                            <Grid item
                                xs={12}
                                md={12}
                                lg={6}
                                xl={6}
                            >
                                <VTextField
                                    label="Nome ou Título do Projeto"
                                    name="name"
                                    fullWidth
                                    required
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid item
                                xs={12}
                                md={6}
                                lg={3}
                                xl={2}
                            >
                                <VTextField
                                    label="Número de participantes"
                                    name="maximumNumberParticipants"
                                    type="number"
                                    defaultValue={0}
                                    fullWidth
                                    toUpper
                                    disabled={loading}
                                />
                            </Grid>

                            <Grid item
                                xs={12}
                                md={6}
                                lg={3}
                                xl={2}
                            >
                                <VTextField
                                    label="Carga Horária Diária Alunos"
                                    name="dailyWorkload"
                                    type="number"
                                    required
                                    defaultValue={0}
                                    fullWidth
                                    toUpper
                                    disabled={loading}
                                />
                            </Grid>
                        </Grid>



                        <Grid container item direction="row" spacing={2}>
                            <Conditional condition={Number(id) === 0}>
                                <Grid item
                                    xs={12}
                                    md={12}
                                    lg={6}
                                    xl={6}
                                >
                                    <VSelect
                                        required
                                        name="course_ExtensionProjects"
                                        options={allCourses.map(course => ({ id: course.id, text: `${course.acronym} - ${course.name}` }))}
                                        label="Cursos Relacionados"
                                    />
                                </Grid>
                            </Conditional>

                            <Grid item
                                xs={12}
                                md={6}
                                lg={3}
                                xl={2}
                            >
                                <VDatePicker
                                    name="startDateAux"
                                    label="Data de início *"
                                />
                            </Grid>

                            <Grid item
                                xs={12}
                                md={6}
                                lg={3}
                                xl={2}
                            >
                                <VDatePicker
                                    name="endDateAux"
                                    label="Data de finalização *"
                                />
                            </Grid>

                        </Grid>

                        <Grid container item direction="row" spacing={2}>
                            <Grid item
                                xs={12}
                                md={12}
                                lg={12}
                                xl={10}
                            >
                                <VTextarea
                                    placeholder="Descrição *"
                                    name="description"
                                    required
                                    disabled={loading}
                                    minRows={8}
                                    maxLength={500}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item direction="row" spacing={2}>
                            <Grid item
                                xs={8}
                                md={4}
                                lg={3}
                                xl={3}
                            >
                                <FormControlLabel

                                    control={
                                        <VCheckbox onChange={
                                            () => {

                                                setIsPublic(!isPublic)
                                                if (!isPublic === true)
                                                    publicAddressRef.current?.scrollIntoView({ behavior: 'smooth' });
                                            }
                                        }
                                            name="isPublic" />
                                    }

                                    label="Projeto aberto ao público?"
                                />
                            </Grid>
                        </Grid>

                        <Grid container item direction="row" spacing={2} >

                            <Grid item
                                xs={12}
                                md={12}
                                lg={6}
                                xl={5}
                            >
                                <VTextField
                                    label="Endereço Público (Onde acontecerá o projeto?)"
                                    name="publicAddress"
                                    required={isPublic}
                                    fullWidth
                                    disabled={loading}
                                />
                            </Grid>

                            <Grid item
                                xs={12}
                                md={12}
                                lg={6}
                                xl={5}
                            >
                                <VTextField
                                    label="Dados de contato (Informação para o público entrar em contato)"
                                    name="publicContactDescription"
                                    required={isPublic}
                                    fullWidth
                                    disabled={loading}
                                />
                            </Grid>

                        </Grid>

                    </Grid>

                </Box>
            </VForm>

        </BasePageLayout>
    )
};
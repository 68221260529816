import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from 'notistack';

import './shared/forms/TranslationsYup';

import { AppRoutes } from './routes';
import { OptInLGPD, SideMenu, Login } from './shared/components';
import { AuthProvider, AppThemeProvider, DrawerProvider } from './shared/contexts';

export const App = () => {
  return (
    <AuthProvider>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={2000}
      >
        <AppThemeProvider>

          <BrowserRouter>
            <Login>

              <OptInLGPD>

                <DrawerProvider>

                  <SideMenu>
                    <AppRoutes />
                  </SideMenu>

                </DrawerProvider>

              </OptInLGPD>

            </Login>
          </BrowserRouter>

        </AppThemeProvider>
      </SnackbarProvider>
    </AuthProvider>
  );
}
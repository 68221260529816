import { Box, Button, Icon, IconButton, Paper, TextField, Theme, useMediaQuery } from "@mui/material";

import { Environment } from "../../environment";

interface IListingToolbarProps {
    searchText?: string;
    showSearchIpunt?: boolean;
    onChangeSearchText?: (newText: string) => void;
    textButton?: string;
    showButton?: boolean;
    onClickButtonAdd?: () => void;
    onClickSearch?: () => void;

}

export const ListingToolbar: React.FC<IListingToolbarProps> = ({
    searchText = '',
    showSearchIpunt = false,
    onChangeSearchText,
    textButton = 'Novo',
    showButton = true,
    onClickButtonAdd,
    onClickSearch
}) => {

    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <Box
            gap={1}
            marginX={1}
            padding={1}
            paddingX={2}
            display="flex"
            alignItems="center"
            component={Paper}
            flexWrap="wrap"
        >

            {
                showSearchIpunt &&
                <TextField
                    size="small"
                    value={searchText}
                    onChange={(e) => onChangeSearchText?.(e.target.value)}
                    placeholder={Environment.SEARCH_INPUT}
                />
            }


            <Box
                flex={1}
                gap={1}
                display="flex" justifyContent="end"
            >
                {
                    !smDown
                    &&
                    (
                        <Box
                            flex={1}
                            gap={1}
                            display="flex" justifyContent="end"
                        >

                            <Button
                                variant="contained"
                                color="primary"
                                endIcon={<Icon>search</Icon>}
                                onClick={onClickSearch}
                                size="small"
                            >
                                Pesquisar
                            </Button>

                        </Box>
                    )
                }

                {
                    smDown
                    &&
                    (
                        <Box
                            flex={1}
                            gap={1}
                            display="flex" justifyContent="end"
                        >

                            <IconButton
                                color="primary"
                                size="small"
                                onClick={onClickSearch}
                            >
                                <Icon>search</Icon>
                            </IconButton>

                        </Box>
                    )
                }

                {
                    showButton &&
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<Icon>add</Icon>}
                        onClick={onClickButtonAdd}
                    >
                        {textButton}
                    </Button>
                }
            </Box>

        </Box>
    );
}
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    Icon, IconButton, LinearProgress,
    Pagination, Paper, Table,
    TableBody, TableCell, TableContainer,
    TableFooter, TableHead, TableRow
} from "@mui/material";

import { ListingToolbar } from "../../shared/components";
import { Environment } from "../../shared/environment";
import { IUserSearchResponse } from "../../shared/interfaces";
import { BasePageLayout } from "../../shared/layouts";
import { UserService } from "../../shared/services/api";

export const UsersList: React.FC = () => {

    const [searchParam, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [usersList, setUsersList] = useState<IUserSearchResponse>();

    const [loading, setLoading] = useState<boolean>(false);
    const [rowCount, setRowCount] = useState<number>(0);

    const searchText = useMemo(() => {
        return searchParam.get('search') || '';
    }, [searchParam]);

    const email = useMemo(() => {
        return searchParam.get('email') || '';
    }, [searchParam]);

    const page = useMemo(() => {
        return Number(searchParam.get('page') || '1');
    }, [searchParam]);

    const countRef = useRef(0);
    
    useEffect(() => {

        if (process.env.NODE_ENV === 'development') {
            if (countRef.current === 0) {
                countRef.current = 1;
                return;
            }
        }

        handleSearch();

    }, [page]);

    const handleSearch = () => {

        setLoading(true);

        UserService.search(searchText, undefined, page - 1)
            .then((result) => {

                if (result instanceof Error) {
                    return;
                }

                setUsersList(result);
                setRowCount(result.rowsCount);

            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <BasePageLayout
            title="Usuários"
            toolbar={(
                <ListingToolbar
                    showSearchIpunt
                    showButton={false}
                    searchText={searchText}
                    onChangeSearchText={text => setSearchParams({ search: text, page: '1' }, { replace: true })}
                    onClickButtonAdd={() => navigate("/courses/new")}
                    onClickSearch={() => handleSearch()}
                />
            )}>

            <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
                {
                    loading &&
                    <LinearProgress variant='indeterminate' />
                }
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>E-mail</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersList?.items?.map(row => (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>
                                    <IconButton size="small" onClick={() => navigate(`/users/${row.id}`)}>
                                        <Icon>remove_red_eye</Icon>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                    {rowCount === 0 && !loading && (
                        <caption>Nenhum registro encontrado</caption>
                    )}

                    <TableFooter>
                        {
                            rowCount > 0 &&
                            rowCount > Environment.ROWS_LIMIT10 &&
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Pagination
                                        page={page}
                                        count={Math.ceil(rowCount / Environment.ROWS_LIMIT10)}
                                        onChange={(_, newPage) => {
                                            setSearchParams({ search: searchText, page: newPage.toString() }, { replace: true })
                                            // handleSearch();
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        }
                    </TableFooter>
                </Table>
            </TableContainer>


        </BasePageLayout>
    )

}
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid,
    Icon, IconButton, LinearProgress, Link, List,
    ListItem, ListItemText, Paper, Stack, Tab,
    Tabs, Tooltip, Typography
} from "@mui/material";
import { format } from "date-fns";

import {
    ApplyProjectToolbar, ResponsibleUserToolbar, a11yProps, CustomTabPanel,
    ExtensionProjectStatusChip, ConfirmDialog, ParticipationStatusChip,
    Conditional
} from "../../shared/components";
import { useAuthContext } from "../../shared/contexts";
import { Environment } from "../../shared/environment";
import { useVForm, VForm, VSelect } from "../../shared/forms";
import {
    IExtensionProjectResponse, ICourseResponse, IUser_ExtensionProjectParticipationStatusLogSearchResponse,
    ICourse_ExtensionProjectPost2, Teacher_ExtensionProjectResponse,
    Student_ExtensionProjectResponse, ExtensionProjectStatusLogResponse, IReasonRejection
} from "../../shared/interfaces";
import { BasePageLayout } from "../../shared/layouts";
import { ExtensionProjectService, UserService, CourseService } from "../../shared/services/api";
import ReportsList from "./ReportsList";

export const ExtensionProjectDetail: React.FC = () => {

    const { id } = useParams<'id'>();
    const { userId, roles } = useAuthContext();
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const [extensionProject, setExtensionProject] = useState<IExtensionProjectResponse>();
    const [teacher_ExtensionProjects, setTeacher_ExtensionProjects] = useState<Teacher_ExtensionProjectResponse[]>([]);
    const [student_ExtensionProjects, setStudent_ExtensionProjects] = useState<Student_ExtensionProjectResponse[]>([]);
    const [extensionProjectStatusLogs, setExtensionProjectStatusLog] = useState<ExtensionProjectStatusLogResponse[]>([]);
    const [reasonRejections, setReasonRejections] = useState<IReasonRejection[]>([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [canEdit, setCanEdit] = useState(false);
    const [canCreateReports, setCanCreateReports] = useState(false);
    const [canAccesEditForm, setCanAccesEditForm] = useState(false);
    const [canNavigate, setCanNavigate] = useState(false);
    const [canCandidate, setCanCandidate] = useState(false);
    const [canCancelCandidate, setCanCancelCandidate] = useState(false);
    const [dialogApproveOpen, setDialogApproveOpen] = useState(false);
    const [dialogDisapproveOpen, setDialogDisapproveOpen] = useState(false);
    const [dialogCancelOpen, setDialogCancelOpen] = useState(false);
    const [dialogAddCourseOpen, setDialogAddCourseOpen] = useState(false);
    const [userIdApproval, setUserIdApproval] = useState(0);
    const [userIdCancel, setUserIdCancel] = useState(0);
    const [allCourses, setAllCourses] = useState<ICourseResponse[]>([]);
    const [dialogDeleteCourseOpen, setDialogDeleteCourseOpen] = useState(false);
    const [courseIdDelete, setCourseIdDelete] = useState(0);
    const [dialogUser_ExtensionProjectParticipationStatusLogs, setDialogUser_ExtensionProjectParticipationStatusLogs] = useState(false);
    const [User_ExtensionProjectParticipationStatusLogs, setUser_ExtensionProjectParticipationStatusLogs] = useState<IUser_ExtensionProjectParticipationStatusLogSearchResponse>();
    const [userIdFinalizeTeacher, setUserIdFinalizeTeacher] = useState(0);
    const [dialogFinalizeTeacherParticipationOpen, setDialogFinalizeTeacherParticipationOpen] = useState(false);
    const [dialogTransferResponsabilityOpen, setDialogTransferResponsabilityOpen] = useState(false);
    const [userTransferResponsability, setUserTransferResponsability] = useState(0);

    const [loadingExtensionProjectData, setLoadingExtensionProjectData] = useState(false);
    const [loadingApplyExtensionProject, setLoadingApplyExtensionProject] = useState(false);
    const [loadingAddCourse, setLoadingAddCourse] = useState(false);

    const [expanded, setExpanded] = useState<string | false>('panel1');
    const [tabNames, setTabNames] = useState<string[]>([]);

    const handleChangeAccordion =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    useEffect(() => {
        // Atualiza as abas disponíveis após applyRules ser executado
        const availableTabs: string[] = ["Visão Geral"];

        if (canNavigate) {
            availableTabs.push("Participantes");
        }

        if (canCreateReports) {
            availableTabs.push("Meus relatórios");
        }

        if (canEdit &&
            (
                extensionProject?.status === 1
                || extensionProject?.status === 4
            )
        ) {
            availableTabs.push("Aprovação de relatórios");
        }

        if (canEdit || roles?.includes(Environment.ROLE_COLEGIADO)) {
            availableTabs.push("Histórico do projeto");
        }

        setTabNames(availableTabs);
    }, [canNavigate, canCreateReports, canEdit, roles]);

    useEffect(() => {
        // Verifica se o usuário está recarregando a página
        const navigationEntry = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
        const isReload = navigationEntry.type === 'reload';

        if (isReload) {
            const savedTab = localStorage.getItem('selectedTab_' + id);
            if (savedTab) {
                setSelectedTab(Number(savedTab));
            }
        }

        // Reseta o localStorage quando o componente é montado, para que, ao acessar de outra forma, vá para a primeira aba
        localStorage.removeItem('selectedTab_' + id);

        handleGetExtensionProject(Number(id));
        handleGetExtensionProjectParticipants();
    }, [id]);

    const getTabOnClick = (name: string) => {
        if (name === "Histórico do projeto") {
            return () => {
                handleGetExtensionProjectLogs(Number(id));
            };
        }
        return undefined; // Para abas que não precisam de onClick
    };

    const {
        formRef,
    } = useVForm();

    const handleChangeSelectedTab = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
        localStorage.setItem('selectedTab_' + id, String(newValue));
    };

    const applyRules = (ep: IExtensionProjectResponse) => {

        if (
            ep.responsibleUserId === Number(userId)
            ||
            ep.teacher_ExtensionProjects?.some((teacher) => teacher.userId === Number(userId)
                &&
                teacher.status === 1)

        ) {
            setCanEdit(true);

            if (ep.status !== 0 && ep.status !== 2 && ep.status !== 3)
                setCanCreateReports(true);
            //verificar se pode acessa o formulário de edição
            if (
                ep.status === 0 ||
                ep.status === 2 ||
                ep?.course_ExtensionProjects?.some((cep) => cep.status === 2)

            ) {
                setCanAccesEditForm(true);
            }

        }

        if (
            ep.student_ExtensionProjects?.some((student) => student.userId === Number(userId)
                && (student.status !== 0 && student.status !== 4 && student.status !== 6))
            ||
            ep.teacher_ExtensionProjects?.some((teacher) => teacher.userId === Number(userId)
                && (teacher.status !== 0 && teacher.status !== 4 && teacher.status !== 6))
        ) {
            setCanCreateReports(true);
        }

        if (
            ep.status === 0 ||
            ep.status === 2 ||
            ep.status === 3) {
            setCanNavigate(false);
        }
        else {
            setCanNavigate(true);
        }

    }

    const handleGetExtensionProject = (id: number) => {

        if (!id) {
            enqueueSnackbar(
                <Typography >
                    Projeto não encontrado
                </Typography>,
                { variant: 'error' });

            navigate("/extension-projects")
        }

        setLoadingExtensionProjectData(true);

        ExtensionProjectService.getById(Number(id))
            .then((result) => {

                if (result instanceof Error) {
                    navigate('/extension-projects');
                } else {
                    if (!result) {
                        enqueueSnackbar(
                            <Typography >
                                Projeto de extensão não encontrado
                            </Typography>,
                            { variant: 'error' });

                        navigate("/extension-projects")
                    }

                    setExtensionProject(result);
                    applyRules(result);
                }
            })
            .finally(() => {
                setLoadingExtensionProjectData(false);
            })
    }

    const handleGetExtensionProjectParticipants = (canReload: boolean = false) => {

        if (!extensionProject || canReload) {

            setLoadingExtensionProjectData(true);

            ExtensionProjectService.getExtensionProjectParticipants(Number(id))
                .then((result) => {

                    if (result instanceof Error) {
                        navigate('/extension-projects');
                    } else {
                        if (!result) {
                            enqueueSnackbar(
                                <Typography >
                                    Projeto de extensão não encontrado
                                </Typography>,
                                { variant: 'error' });

                            navigate("/extension-projects")
                        }

                        setTeacher_ExtensionProjects(result.teacher_ExtensionProjects ?? []);
                        setStudent_ExtensionProjects(result.student_ExtensionProjects ?? []);

                        if (!result.student_ExtensionProjects?.some((item) => item.userId === Number(userId))
                            && !result.teacher_ExtensionProjects?.some((item) => item.userId === Number(userId))) {
                            setCanCandidate(true);
                        }
                        else {
                            setCanCandidate(false);
                        }

                        if (
                            result?.student_ExtensionProjects?.some((item) => item.userId === Number(userId) && item.status === 0)
                            ||
                            result?.teacher_ExtensionProjects?.some((item) => item.userId === Number(userId) && item.status === 0)) {
                            setCanCancelCandidate(true);
                        } else {
                            setCanCancelCandidate(false);
                        }

                        applyRules(result);

                    }
                })
                .finally(() => {
                    setLoadingExtensionProjectData(false);
                });
        }
    }

    const handleGetExtensionProjectLogs = (id: number) => {

        if (!id) {
            enqueueSnackbar(
                <Typography >
                    Projeto não encontrado
                </Typography>,
                { variant: 'error' });

            navigate("/extension-projects")
        }

        if (reasonRejections?.length === 0 && extensionProjectStatusLogs?.length === 0) {

            setLoadingExtensionProjectData(true);

            ExtensionProjectService.getExtensionProjectLogs(Number(id))
                .then((result) => {

                    if (result instanceof Error) {
                        navigate('/extension-projects');
                    } else {
                        if (!result) {
                            enqueueSnackbar(
                                <Typography >
                                    Projeto de extensão não encontrado
                                </Typography>,
                                { variant: 'error' });

                            navigate("/extension-projects")
                        }

                        setReasonRejections(result.reasonRejections ?? []);
                        setExtensionProjectStatusLog(result.extensionProjectStatusLogs ?? []);

                    }
                })
                .finally(() => {
                    setLoadingExtensionProjectData(false);
                })
        }
    }

    const applyExtensionProject = () => {

        setLoadingApplyExtensionProject(true);

        ExtensionProjectService.ApplyForExtensionProject({
            extensionProjectId: Number(id)
        }).then((result) => {

            if (result instanceof Error) {

                return;

            } else {

                enqueueSnackbar(
                    <Typography >
                        Candidatura realizada com sucesso
                    </Typography>,
                    { variant: 'success' });

                handleGetExtensionProjectParticipants(true);

            }
        }).finally(() => {
            setLoadingApplyExtensionProject(false);
        });
    };

    const cancelApplyExtensionProject = () => {

        setLoadingApplyExtensionProject(true);

        if (roles.includes(Environment.ROLE_PROFESSOR)) {

            UserService.cancelTeacherParticipation({
                extensionProjectId: Number(id),
                userId: userId
            }).then((result) => {

                if (result instanceof Error) {

                    return;

                } else {

                    enqueueSnackbar(
                        <Typography >
                            Candidatura cancelada com sucesso
                        </Typography>,
                        { variant: 'success' });

                    handleGetExtensionProjectParticipants(true);

                }
            }).finally(() => {
                setLoadingApplyExtensionProject(false);
            });
        }
        else {

            UserService.cancelStudentParticipation({
                extensionProjectId: Number(id),
                userId: userId
            }).then((result) => {

                if (result instanceof Error) {

                    return;

                } else {

                    enqueueSnackbar(
                        <Typography >
                            Candidatura cancelada com sucesso
                        </Typography>,
                        { variant: 'success' });

                    handleGetExtensionProjectParticipants(true);

                }
            }).finally(() => {
                setLoadingApplyExtensionProject(false);
            });
        }
    };

    const disapproveParticipation = () => {

        ExtensionProjectService.DisapproveParticipation({
            extensionProjectId: Number(id),
            userId: userIdApproval
        }).then((result) => {

            if (result instanceof Error) {

                return;

            } else {

                handleGetExtensionProject(Number(id));

                enqueueSnackbar(
                    <Typography >
                        Candidatura reprovada com sucesso
                    </Typography>,
                    { variant: 'success' });

                handleGetExtensionProjectParticipants(true);

            }
        }).finally(() => {
            setLoadingExtensionProjectData(false);
        });
    };

    const approveParticipation = () => {
        ExtensionProjectService.ApproveParticipation({
            extensionProjectId: Number(id),
            userId: userIdApproval
        }).then((result) => {

            if (result instanceof Error) {

                return;

            } else {

                enqueueSnackbar(
                    <Typography >
                        Candidatura aprovada com sucesso
                    </Typography>,
                    { variant: 'success' });

                handleGetExtensionProjectParticipants(true);

            }
        }).finally(() => {
            setLoadingExtensionProjectData(false);
        });
    };

    const cancelParticipation = () => {

        UserService.cancelStudentParticipation({
            extensionProjectId: Number(id),
            userId: userIdCancel
        }).then((result) => {

            if (result instanceof Error) {

                return;

            } else {

                handleGetExtensionProjectParticipants(true);

                enqueueSnackbar(
                    <Typography >
                        Participação cancelada com sucesso
                    </Typography>,
                    { variant: 'success' });

                handleGetExtensionProjectParticipants(true);

            }

        }).finally(() => {
            setLoadingExtensionProjectData(false);
        });
    };

    const finalizeTeacherParticipation = () => {

        ExtensionProjectService.FinalizeTeacherParticipation({
            extensionProjectId: Number(id),
            userId: userIdFinalizeTeacher
        }).then((result) => {

            if (result instanceof Error) {

                return;

            } else {

                enqueueSnackbar(
                    <Typography >
                        Participação cancelada com sucesso
                    </Typography>,
                    { variant: 'success' });

                handleGetExtensionProjectParticipants(true);
            }


        }).finally(() => {
            setLoadingExtensionProjectData(false);
        });
    };

    const transferResponsability = () => {

        ExtensionProjectService.transferResponsibility({
            extensionProjectId: Number(id),
            userId: userTransferResponsability
        }).then((result) => {

            if (result instanceof Error) {

                return;

            } else {

                enqueueSnackbar(
                    <Typography >
                        Responsabilidade transferida com sucesso
                    </Typography>,
                    { variant: 'success' });

                handleGetExtensionProject(Number(id));
                handleGetExtensionProjectParticipants(true);
            }


        }).finally(() => {
            setLoadingExtensionProjectData(false);
        });
    };

    const getExtensionProjectAttachment = () => {

        setLoadingExtensionProjectData(true);

        ExtensionProjectService.getAtachment(Number(id))
            .then((result) => {

                if (result instanceof Error) {
                    return;
                }

            })
            .finally(() => {
                setLoadingExtensionProjectData(false);
            });
    }

    const finishProject = () => {

        setLoadingExtensionProjectData(true);

        ExtensionProjectService.FinalizeExtensionProject(Number(id))
            .then((result) => {

                if (result instanceof Error) {

                    return;

                }
                else {

                    enqueueSnackbar(
                        <Typography >
                            Projeto finalizado com sucesso
                        </Typography>,
                        { variant: 'success' });

                    setExtensionProject(result);
                }


            })
            .finally(() => {
                setLoadingExtensionProjectData(false);
            });
    }

    const openOrCloseApplication = () => {

        setLoadingExtensionProjectData(true);

        if (extensionProject?.openForApplications) {

            ExtensionProjectService.CloseForApplication(Number(id))
                .then((result) => {

                    if (result instanceof Error) {

                        return;

                    }
                    else {

                        enqueueSnackbar(
                            <Typography >
                                Candidaturas fechadas com sucesso
                            </Typography>,
                            { variant: 'success' });

                        setExtensionProject(result);
                    }


                })
                .finally(() => {
                    setLoadingExtensionProjectData(false);
                });
        }
        else {

            ExtensionProjectService.OpenForApplication(Number(id))
                .then((result) => {

                    if (result instanceof Error) {

                        return;

                    }
                    else {

                        enqueueSnackbar(
                            <Typography >
                                Candidaturas abertas com sucesso
                            </Typography>,
                            { variant: 'success' });

                        setExtensionProject(result);
                    }


                })
                .finally(() => {
                    setLoadingExtensionProjectData(false);
                });
        }


    }

    const handleGetAllCourses = () => {

        if (allCourses.length === 0) {

            CourseService.getAll()
                .then((result) => {

                    if (result instanceof Error) {

                        return;
                    }

                    setAllCourses(result);

                });
        }
    }

    const handleAddCourse = (formData: ICourse_ExtensionProjectPost2 | any) => {

        formData.extensionProjectId = Number(id);
        formData.courseId = Number(formData.courseId[0]);

        setLoadingAddCourse(true);

        ExtensionProjectService.addCourseExtensionProject(formData).then((result) => {

            if (result instanceof Error) {
                return;

            } else {

                enqueueSnackbar(
                    <Typography >
                        Curso adicionado com sucesso
                    </Typography>,
                    { variant: 'success' });

                handleGetExtensionProject(Number(id));
                setDialogAddCourseOpen(false);
            }
        }).finally(() => {
            setLoadingAddCourse(false);
        });
    };

    const deleteCourse = () => {

        setLoadingExtensionProjectData(true);

        ExtensionProjectService.deleteCourseExtensionProject(
            Number(id),
            courseIdDelete
        ).then((result) => {

            if (result instanceof Error) {
                return;

            } else {

                enqueueSnackbar(
                    <Typography >
                        Curso removido com sucesso
                    </Typography>,
                    { variant: 'success' });

                handleGetExtensionProject(Number(id));
                setDialogDeleteCourseOpen(false);
            }
        }).finally(() => {
            setLoadingExtensionProjectData(false);
        });
    };

    const handleGetUser_ExtensionProjectParticipationStatusLogs = (userId: number) => {

        setLoadingExtensionProjectData(true);

        ExtensionProjectService.SearchUser_ExtensionProjectParticipationStatusLog(
            userId,
            Number(id)
        ).then((result) => {

            if (result instanceof Error) {
                return;

            } else {
                setUser_ExtensionProjectParticipationStatusLogs(result);
            }
        }).finally(() => {
            setLoadingExtensionProjectData(false);
        });
    };


    return (
        <BasePageLayout
            title={`Projeto #GEEU${extensionProject?.id}`}
            toolbar={
                <>
                    <Conditional condition={
                        !canEdit
                        && canNavigate
                        && (extensionProject?.openForApplications ?? false)
                        && (canCandidate || canCancelCandidate)
                    }>
                        <Box>
                            <Conditional condition={loadingExtensionProjectData || loadingApplyExtensionProject}>
                                <LinearProgress variant='indeterminate' />
                            </Conditional>
                            <ApplyProjectToolbar
                                onClickButtonApply={applyExtensionProject}
                                onClickButtonCancel={cancelApplyExtensionProject}
                                showButtonLoading={loadingApplyExtensionProject}
                                showApplyButton={canCandidate}
                                showCancelButton={canCancelCandidate}
                            />
                        </Box>
                    </Conditional>

                    <Conditional condition={canEdit
                        &&
                        (
                            extensionProject?.status === 4
                            || extensionProject?.status === 2
                            || extensionProject?.status === 0
                        )
                    }>
                        <ResponsibleUserToolbar
                            extensionProjectId={Number(id)}
                            canEdit={canAccesEditForm}
                            canFinishProject={extensionProject?.status === 4}
                            openForApplcation={extensionProject?.openForApplications ?? false}
                            loading={loadingExtensionProjectData}
                            finishProject={finishProject}
                            openOrCloseApplication={openOrCloseApplication}
                        />
                    </Conditional>
                </>
            }
        >
            <Box
                margin={1}
                display="flex"
                flexDirection="column"
                component={Paper}
                minHeight={"95%"}
            >
                <Conditional condition={loadingExtensionProjectData}>
                    <LinearProgress variant='indeterminate' />
                </Conditional>
                <Box sx={{ borderColor: 'divider' }}>
                    <Tabs
                        value={selectedTab}
                        onChange={handleChangeSelectedTab}
                        variant="scrollable"
                    >

                        {tabNames.map((name, index) => (
                            <Tab
                                key={index}
                                label={
                                    name === "Participantes" ? (
                                        <Badge
                                            color="secondary"
                                            variant="dot"
                                            invisible={!(
                                                (student_ExtensionProjects?.some((item) => item.status === 0)
                                                    || teacher_ExtensionProjects?.some((item) => item.status === 0))
                                                && canEdit)}
                                        >
                                            {name}
                                        </Badge>
                                    ) : (
                                        name
                                    )
                                }
                                onClick={getTabOnClick(name)}
                                {...a11yProps(index)}
                            />
                        ))}

                    </Tabs>


                </Box>

                {tabNames.map((name, index) => (
                    <CustomTabPanel key={index} value={selectedTab} index={index}>
                        {name === "Visão Geral" && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={5}
                                            xl={5}
                                        >
                                            <Card>
                                                <CardHeader
                                                    title={extensionProject?.name}
                                                    titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
                                                    sx={{
                                                        backgroundColor: (theme) => theme.palette.primary.main, // Usando a cor principal do tema
                                                        color: (theme) => theme.palette.primary.contrastText, // Texto contrastante adaptado ao tema
                                                        padding: 1, // Altura reduzida
                                                        borderTopLeftRadius: 8,
                                                        borderTopRightRadius: 8,
                                                        borderBottom: 1,
                                                        borderBottomColor: (theme) => theme.palette.divider, // Usando a cor do divisor do tema
                                                        borderBottomStyle: 'solid',
                                                    }}
                                                />
                                                <CardContent sx={{ padding: 3 }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}>
                                                            <Box display="flex" alignItems="center">
                                                                <Icon sx={{ color: 'primary.main', mr: 1 }}>person</Icon>
                                                                <Box>
                                                                    <Typography variant="body2" color="textSecondary">
                                                                        Responsável
                                                                    </Typography>
                                                                    <Typography variant="body1">
                                                                        {extensionProject?.responsibleUser?.name}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Box display="flex" alignItems="center">
                                                                <Icon sx={{ color: 'primary.main', mr: 1 }}>info</Icon>
                                                                <Box>
                                                                    <Typography variant="body2" color="textSecondary">
                                                                        Status
                                                                    </Typography>
                                                                    <Typography variant="body1">
                                                                        <ExtensionProjectStatusChip status={extensionProject?.status ?? 99} />
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Box display="flex" alignItems="center">
                                                                <Icon sx={{ color: 'primary.main', mr: 1 }}>event</Icon>
                                                                <Box>
                                                                    <Typography variant="body2" color="textSecondary">
                                                                        Início
                                                                    </Typography>
                                                                    <Typography variant="body1">
                                                                        {extensionProject?.startDate?.split('-').reverse().join('/')}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Box display="flex" alignItems="center">
                                                                <Icon sx={{ color: 'primary.main', mr: 1 }}>event</Icon>
                                                                <Box>
                                                                    <Typography variant="body2" color="textSecondary">
                                                                        Término
                                                                    </Typography>
                                                                    <Typography variant="body1">
                                                                        {extensionProject?.endDate?.split('-').reverse().join('/')}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Box display="flex" alignItems="center">
                                                                <Icon sx={{ color: 'primary.main', mr: 1 }}>how_to_reg</Icon>
                                                                <Box>
                                                                    <Typography variant="body2" color="textSecondary">
                                                                        Candidaturas
                                                                    </Typography>
                                                                    <Typography variant="body1">
                                                                        {extensionProject?.openForApplications ? "Abertas" : "Fechadas"}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Box>
                                                                <Typography variant="body2" color="textSecondary">
                                                                    Descrição
                                                                </Typography>
                                                                <Typography variant="body1" sx={{ textAlign: 'justify', mt: 1 }}>
                                                                    {extensionProject?.description}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>

                                                    {/* Seção de Informações Públicas */}
                                                    {extensionProject?.isPublic && (
                                                        <>
                                                            <Divider sx={{ my: 3 }} />
                                                            <Typography variant="h6" color="primary" gutterBottom>
                                                                Informações Públicas
                                                            </Typography>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Box display="flex" alignItems="center">
                                                                        <Icon sx={{ color: 'primary.main', mr: 1 }}>location_on</Icon>
                                                                        <Box>
                                                                            <Typography variant="body2" color="textSecondary">
                                                                                Endereço Público
                                                                            </Typography>
                                                                            <Typography variant="body1">
                                                                                {extensionProject?.publicAddress}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Box display="flex" alignItems="center">
                                                                        <Icon sx={{ color: 'primary.main', mr: 1 }}>contact_phone</Icon>
                                                                        <Box>
                                                                            <Typography variant="body2" color="textSecondary">
                                                                                Contato Público
                                                                            </Typography>
                                                                            <Typography variant="body1">
                                                                                {extensionProject?.publicContactDescription}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    )}

                                                    <Divider sx={{ my: 2 }} />
                                                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => getExtensionProjectAttachment()}
                                                            startIcon={<Icon>download</Icon>}
                                                            disabled={loadingExtensionProjectData}
                                                        >
                                                            Baixar arquivo do projeto
                                                        </Button>
                                                    </Box>

                                                    <Typography variant="h6" sx={{ marginTop: 2 }}>
                                                        Cursos envolvidos
                                                        <Conditional condition={
                                                            canEdit &&
                                                            (
                                                                extensionProject?.status === 0
                                                                ||
                                                                extensionProject?.status === 4
                                                            )
                                                        }>
                                                            <Tooltip title="Adicionar Curso">
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setDialogAddCourseOpen(true);
                                                                        handleGetAllCourses();
                                                                    }}
                                                                    disabled={loadingExtensionProjectData}
                                                                >
                                                                    <Icon>add</Icon>
                                                                </IconButton>
                                                            </Tooltip>

                                                            <Dialog
                                                                open={dialogAddCourseOpen}
                                                                onClose={() => setDialogAddCourseOpen(false)}
                                                                fullWidth={true}
                                                                PaperProps={{
                                                                    component: 'form'
                                                                }}
                                                            >
                                                                <Conditional condition={loadingAddCourse}>
                                                                    <LinearProgress variant='indeterminate' />
                                                                </Conditional>

                                                                <VForm ref={formRef} onSubmit={handleAddCourse}>

                                                                    <DialogTitle>
                                                                        Adicionar Curso
                                                                    </DialogTitle>

                                                                    <DialogContent>
                                                                        <Grid container direction="column" padding={1} spacing={2}>
                                                                            <Grid item>
                                                                                <VSelect
                                                                                    name="courseId[0]"
                                                                                    options={allCourses.map(course => ({ id: course.id, text: `${course.acronym} - ${course.name}` }))}
                                                                                    label="Cursos Relacionados"
                                                                                    multiple={false}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </DialogContent>

                                                                    <DialogActions>
                                                                        <Button
                                                                            disabled={loadingAddCourse}
                                                                            onClick={() => setDialogAddCourseOpen(false)}
                                                                        >
                                                                            Cancelar
                                                                        </Button>
                                                                        <Button
                                                                            disabled={loadingAddCourse}
                                                                            onClick={() => formRef.current?.submitForm()}
                                                                        >
                                                                            Confirmar
                                                                        </Button>
                                                                    </DialogActions>

                                                                </VForm>
                                                            </Dialog>

                                                            <ConfirmDialog
                                                                dialogTitle="Remover curso"
                                                                dialogDescription="Deseja remover este curso deste projeto de extensão?"
                                                                onClose={() => { setDialogDeleteCourseOpen(false) }}
                                                                onConfirm={() => {
                                                                    setDialogDeleteCourseOpen(false);
                                                                    deleteCourse();
                                                                }}
                                                                open={dialogDeleteCourseOpen}
                                                            />
                                                        </Conditional>
                                                    </Typography>



                                                    <List
                                                        sx={{
                                                            position: 'relative',
                                                            '& ul': { padding: 0 },
                                                        }}
                                                    >
                                                        {extensionProject?.course_ExtensionProjects?.map((item) => (
                                                            <ListItem key={`section-${item.course.acronym}-${item.course.name}`} sx={{ padding: 0 }}>
                                                                <ListItemText
                                                                    primary={

                                                                        <Box display="flex" justifyContent="space-between" alignItems="center" padding={1}>
                                                                            <Box>
                                                                                {item.course.acronym} - {item.course.name}
                                                                            </Box>

                                                                            <Box display="flex" alignItems="center" flexWrap="wrap">
                                                                                <ExtensionProjectStatusChip status={item?.status ?? 99} />
                                                                                <Conditional condition={item.status === 0 && canEdit}>
                                                                                    <Tooltip title="Remover Curso">
                                                                                        <IconButton
                                                                                            size="small"
                                                                                            onClick={() => {
                                                                                                setCourseIdDelete(item.course.id);
                                                                                                setDialogDeleteCourseOpen(true);
                                                                                            }}
                                                                                            disabled={loadingExtensionProjectData}
                                                                                        >
                                                                                            <Icon>delete</Icon>
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Conditional>
                                                                            </Box>
                                                                        </Box>
                                                                    } />
                                                                <Divider />
                                                            </ListItem>
                                                        ))}

                                                        {(!extensionProject?.course_ExtensionProjects || extensionProject?.course_ExtensionProjects?.length === 0) && (
                                                            <Typography>
                                                                Nenhum registro encontrado
                                                            </Typography>
                                                        )}
                                                    </List>
                                                </CardContent>
                                            </Card>


                                        </Grid>
                                        <Grid item xs={12}
                                            sm={12}
                                            md={12}
                                            lg={7}
                                            xl={7}>
                                            <Card>
                                                <ReportsList extensionProjectId={Number(id)} status={1} />
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {name === "Participantes" && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}
                                            sm={12}
                                            md={12}
                                            lg={6}
                                            xl={6}>
                                            <Card>
                                                <CardHeader
                                                    title="Professores"
                                                    subheader={`Responsável: ${extensionProject?.responsibleUser?.name}`}
                                                    sx={{ paddingBottom: 0 }}
                                                />
                                                <CardContent sx={{ paddingTop: 0 }}>
                                                    <List>
                                                        {teacher_ExtensionProjects?.map((item, index) => (
                                                            <ListItem key={`section-teacher-${index}`}>
                                                                <ListItemText primary={
                                                                    <Box
                                                                        display="flex"
                                                                        flexDirection={{ xs: 'column', sm: 'row' }}  // Coloca as ações abaixo do nome em telas pequenas
                                                                        justifyContent="space-between"
                                                                        alignItems={{ xs: 'flex-start', sm: 'center' }}  // Alinha à esquerda em telas pequenas
                                                                        gap={1}
                                                                    >
                                                                        <Box>
                                                                            <Typography variant="body2">
                                                                                {item.user?.name}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            gap={1}
                                                                            flexWrap="wrap"
                                                                        >
                                                                            <Conditional condition={canEdit || userId === item.user?.id}>
                                                                                <Tooltip title="Histórico de participação">
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        onClick={() => {
                                                                                            setDialogUser_ExtensionProjectParticipationStatusLogs(true);
                                                                                            handleGetUser_ExtensionProjectParticipationStatusLogs(item.user?.id ?? 0);
                                                                                        }}
                                                                                        disabled={loadingExtensionProjectData}
                                                                                    >
                                                                                        <Icon>history</Icon>
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Conditional>
                                                                            <Conditional condition={
                                                                                extensionProject?.responsibleUserId === Number(userId)
                                                                                ||
                                                                                teacher_ExtensionProjects.some((teacher) => teacher.userId === Number(userId) && teacher.status === 1)}
                                                                            >
                                                                                <Conditional condition={item.status === 0}>
                                                                                    <Tooltip title="Aprovar participação">
                                                                                        <IconButton
                                                                                            size="small"
                                                                                            onClick={() => {
                                                                                                setDialogApproveOpen(true);
                                                                                                setUserIdApproval(item.user?.id ?? 0);
                                                                                            }}
                                                                                            disabled={loadingExtensionProjectData}
                                                                                            color="success"
                                                                                        >
                                                                                            <Icon>check_circle</Icon>
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    <Tooltip title="Reprovar participação">
                                                                                        <IconButton
                                                                                            size="small"
                                                                                            onClick={() => {
                                                                                                setDialogDisapproveOpen(true);
                                                                                                setUserIdApproval(item.user?.id ?? 0);
                                                                                            }}
                                                                                            disabled={loadingExtensionProjectData}
                                                                                            color="error"
                                                                                        >
                                                                                            <Icon>cancel</Icon>
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Conditional>
                                                                            </Conditional>

                                                                            <Conditional condition={
                                                                                (canEdit || item.user?.id === Number(userId)) && item.status === 1
                                                                            }>

                                                                                <Conditional condition={
                                                                                    extensionProject?.responsibleUserId === Number(userId)
                                                                                    ||
                                                                                    roles?.includes(Environment.ROLE_ADMIN)
                                                                                }>
                                                                                    <Tooltip title="Transferir responsabilidade">
                                                                                        <IconButton
                                                                                            size="small"
                                                                                            onClick={() => {
                                                                                                setDialogTransferResponsabilityOpen(true);
                                                                                                setUserTransferResponsability(item.user?.id ?? 0);
                                                                                            }}
                                                                                            disabled={loadingExtensionProjectData}
                                                                                            color="secondary"
                                                                                        >
                                                                                            <Icon>compare_arrows</Icon>
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Conditional>

                                                                                <Tooltip title="Finalizar participação">
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        onClick={() => {
                                                                                            setDialogFinalizeTeacherParticipationOpen(true);
                                                                                            setUserIdFinalizeTeacher(item.user?.id ?? 0);
                                                                                        }}
                                                                                        disabled={loadingExtensionProjectData}
                                                                                        color="success"
                                                                                    >
                                                                                        <Icon>done_all</Icon>
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Conditional>
                                                                            <ParticipationStatusChip status={item.status ?? 99} />
                                                                        </Box>
                                                                    </Box>
                                                                } />

                                                            </ListItem>

                                                        ))}

                                                        {(!teacher_ExtensionProjects || teacher_ExtensionProjects?.length === 0) && (
                                                            <Typography>
                                                                Nenhum professor vinculado além do responsável
                                                            </Typography>
                                                        )}

                                                    </List>

                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={12}
                                            sm={12}
                                            md={12}
                                            lg={6}
                                            xl={6}>
                                            <Card>
                                                <CardHeader
                                                    title={`Alunos`}
                                                    subheader={`Participantes Ativos: ${student_ExtensionProjects?.filter((item) => item.status === 1).length}`}
                                                />
                                                <CardContent sx={{
                                                    padding: 1
                                                }}>
                                                    {extensionProject?.course_ExtensionProjects?.filter((item) => item.status === 1 || item.status === 6).map((course_ExtensionProjects, index) => (
                                                        <Accordion
                                                            sx={{
                                                                padding: 0
                                                            }}
                                                            expanded={expanded === course_ExtensionProjects.course.acronym}
                                                            onChange={handleChangeAccordion(course_ExtensionProjects.course.acronym)}
                                                            key={`accordion-studen-${index}`}
                                                        >
                                                            <AccordionSummary
                                                                aria-controls={`${course_ExtensionProjects.course.acronym}-content`}
                                                                id={`${course_ExtensionProjects.course.acronym}-header`}
                                                            >
                                                                {
                                                                    expanded === course_ExtensionProjects.course.acronym
                                                                        ? <Icon>expand_less</Icon>
                                                                        : <Icon>expand_more</Icon>
                                                                }

                                                                <Typography>
                                                                    {`${course_ExtensionProjects.course.acronym} - 
                                                                ${course_ExtensionProjects.course.name} (${student_ExtensionProjects?.filter(item => item.user?.course_Users[0].course.acronym === course_ExtensionProjects.course.acronym).length})`}
                                                                </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails key={`accordion-detail-${index}`}
                                                                sx={
                                                                    {
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0
                                                                    }
                                                                }
                                                            >
                                                                <List
                                                                    sx={{
                                                                        height: '100%',
                                                                        position: 'relative',
                                                                        '& ul': { padding: 0 },
                                                                    }}
                                                                >
                                                                    {student_ExtensionProjects?.filter(item => item.user?.course_Users[0].course.acronym === course_ExtensionProjects.course.acronym)
                                                                        .sort((a, b) => (a.user?.name ?? '').localeCompare(b.user?.name ?? ''))
                                                                        .map((item) => (
                                                                            <ListItem key={`section-${item.user?.name}`} sx={{ padding: 0 }}>
                                                                                <ListItemText sx={{ padding: 0 }}
                                                                                    primary={
                                                                                        <Box
                                                                                            display="flex"
                                                                                            flexDirection={{ xs: 'column', sm: 'row' }}  // Coloca as ações abaixo do nome em telas pequenas
                                                                                            justifyContent="space-between"
                                                                                            alignItems={{ xs: 'flex-start', sm: 'center' }}  // Alinha à esquerda em telas pequenas e ao centro em telas maiores
                                                                                            gap={1}
                                                                                        >
                                                                                            <Box>
                                                                                                <Conditional condition={
                                                                                                    canEdit ||
                                                                                                    roles?.includes(Environment.ROLE_PROFESSOR) ||
                                                                                                    roles?.includes(Environment.ROLE_COLEGIADO) ||
                                                                                                    roles?.includes(Environment.ROLE_ADMIN)
                                                                                                }>
                                                                                                    <Tooltip title="Visualizar perfil do usuário">
                                                                                                        <Link href="#" onClick={() => navigate(`/users/${item?.userId}`)}>
                                                                                                            {`${item.user?.course_Users[0].course.acronym} - ${item.user?.registration.trim()} - ${item.user?.name}`}
                                                                                                        </Link>
                                                                                                    </Tooltip>
                                                                                                </Conditional>

                                                                                                <Conditional condition={!canEdit && roles?.includes(Environment.ROLE_ALUNO)}>
                                                                                                    {`${item.user?.course_Users[0].course.acronym} - ${item.user?.registration.trim()} - ${item.user?.name}`}
                                                                                                </Conditional>
                                                                                            </Box>

                                                                                            <Box
                                                                                                display="flex"
                                                                                                alignItems="center"
                                                                                                gap={1}
                                                                                                flexWrap="wrap" // Permite a quebra de linha quando o layout for apertado
                                                                                            >
                                                                                                <Conditional condition={canEdit || userId === item.user?.id}>
                                                                                                    <Tooltip title="Histórico de participação">
                                                                                                        <IconButton
                                                                                                            size="small"
                                                                                                            onClick={() => {
                                                                                                                setDialogUser_ExtensionProjectParticipationStatusLogs(true);
                                                                                                                handleGetUser_ExtensionProjectParticipationStatusLogs(item.user?.id ?? 0);
                                                                                                            }}
                                                                                                            disabled={loadingExtensionProjectData}
                                                                                                        >
                                                                                                            <Icon>history</Icon>
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                </Conditional>

                                                                                                <Conditional condition={
                                                                                                    extensionProject.responsibleUserId === Number(userId)
                                                                                                    ||
                                                                                                    teacher_ExtensionProjects.some((teacher) => teacher.userId === Number(userId) && teacher.status === 1)
                                                                                                }>
                                                                                                    <Conditional condition={item.status === 0}>
                                                                                                        <Tooltip title="Aprovar participação">
                                                                                                            <IconButton
                                                                                                                size="small"
                                                                                                                onClick={() => {
                                                                                                                    setDialogApproveOpen(true);
                                                                                                                    setUserIdApproval(item.user?.id ?? 0);
                                                                                                                }}
                                                                                                                disabled={loadingExtensionProjectData}
                                                                                                                color="success"
                                                                                                            >
                                                                                                                <Icon>check_circle</Icon>
                                                                                                            </IconButton>
                                                                                                        </Tooltip>

                                                                                                        <Tooltip title="Reprovar participação">
                                                                                                            <IconButton
                                                                                                                size="small"
                                                                                                                onClick={() => {
                                                                                                                    setDialogDisapproveOpen(true);
                                                                                                                    setUserIdApproval(item.user?.id ?? 0);
                                                                                                                }}
                                                                                                                disabled={loadingExtensionProjectData}
                                                                                                                color="error"
                                                                                                            >
                                                                                                                <Icon>cancel</Icon>
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    </Conditional>

                                                                                                    <Conditional condition={item.status === 1}>
                                                                                                        <Tooltip title="Cancelar participação">
                                                                                                            <IconButton
                                                                                                                size="small"
                                                                                                                onClick={() => {
                                                                                                                    setDialogCancelOpen(true);
                                                                                                                    setUserIdCancel(item.user?.id ?? 0);
                                                                                                                }}
                                                                                                                disabled={loadingExtensionProjectData}
                                                                                                                color="error"
                                                                                                            >
                                                                                                                <Icon>cancel</Icon>
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    </Conditional>


                                                                                                </Conditional>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    }
                                                                                    secondary={
                                                                                        <>
                                                                                            <ParticipationStatusChip status={item.status ?? 99} />
                                                                                            <Conditional condition={item.status === 1 || item.status === 2 || item.status === 3}>
                                                                                                <Typography variant="body2">
                                                                                                    <strong>Início:</strong> {format(new Date(item?.startDate ?? "1900-01-01") ?? new Date(), 'dd/MM/yyyy')}
                                                                                                </Typography>
                                                                                            </Conditional>

                                                                                            <Conditional condition={item.status === 3}>
                                                                                                <Typography variant="body2">
                                                                                                    <strong>Término:</strong> {format(new Date(item?.endDate ?? "1900-01-01") ?? new Date(), 'dd/MM/yyyy')}
                                                                                                </Typography>

                                                                                                <Typography variant="body2">
                                                                                                    <strong>Carga Horária:</strong> {item?.workload ?? 0}
                                                                                                </Typography>
                                                                                            </Conditional>
                                                                                        </>
                                                                                    }
                                                                                />
                                                                                <Divider />
                                                                            </ListItem>

                                                                        ))}
                                                                    {student_ExtensionProjects?.filter(item => item.user?.course_Users[0].course.acronym === course_ExtensionProjects.course.acronym).length === 0
                                                                        && (
                                                                            <Typography>
                                                                                Nenhum aluno registrado
                                                                            </Typography>
                                                                        )}

                                                                </List>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ))}

                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Dialog
                                    open={dialogUser_ExtensionProjectParticipationStatusLogs}
                                    onClose={() => setDialogUser_ExtensionProjectParticipationStatusLogs(false)}
                                >
                                    <Conditional condition={loadingExtensionProjectData}>
                                        <LinearProgress variant='indeterminate' />
                                    </Conditional>
                                    <DialogTitle>Histórico de status do usuário</DialogTitle>

                                    <DialogContent>
                                        <Grid container direction="column" padding={1} spacing={2}>
                                            <List>
                                                {User_ExtensionProjectParticipationStatusLogs?.items?.map((item) => (
                                                    <ListItem>
                                                        <ListItemText
                                                            key={`section-${item.status}`}
                                                            primary={
                                                                <Stack direction="row" spacing={1}>
                                                                    <ParticipationStatusChip status={item?.status ?? 99} />
                                                                </Stack>
                                                            }
                                                            secondary={
                                                                <>
                                                                    <div>
                                                                        <strong>Data: </strong> {format(new Date(item?.createdAt ?? "1900-01-01") ?? new Date(), 'dd/MM/yyyy HH:mm')}
                                                                    </div>
                                                                    <div>
                                                                        <strong>Alterado por: </strong> {item?.createdBy}
                                                                    </div>
                                                                </>
                                                            }
                                                        >

                                                        </ListItemText>
                                                        <Divider />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Grid>
                                    </DialogContent>
                                </Dialog>

                                <ConfirmDialog
                                    dialogTitle="Aprovar usuário"
                                    dialogDescription="Deseja aprovar a participação deste usuário neste projeto?"
                                    onClose={() => { setDialogApproveOpen(false) }}
                                    onConfirm={() => {
                                        setDialogApproveOpen(false);
                                        setLoadingExtensionProjectData(true);
                                        approveParticipation();
                                    }}
                                    open={dialogApproveOpen}
                                />

                                <ConfirmDialog
                                    dialogTitle="Reprovar usuário"
                                    dialogDescription="Deseja reprovar a participação deste usuário neste projeto?"
                                    onClose={() => { setDialogDisapproveOpen(false) }}
                                    onConfirm={() => {
                                        setDialogDisapproveOpen(false);
                                        setLoadingExtensionProjectData(true);
                                        disapproveParticipation();
                                    }}
                                    open={dialogDisapproveOpen}
                                />

                                <ConfirmDialog
                                    dialogTitle="Cancelar participação"
                                    dialogDescription="Deseja cancelar a participação deste usuário neste projeto?"
                                    onClose={() => { setDialogCancelOpen(false) }}
                                    onConfirm={() => {
                                        setDialogCancelOpen(false);
                                        setLoadingExtensionProjectData(true);
                                        cancelParticipation();
                                    }}
                                    open={dialogCancelOpen}
                                />

                                <ConfirmDialog
                                    dialogTitle="Finalizar participação"
                                    dialogDescription="Deseja finalizar a participação neste projeto?"
                                    onClose={() => { setDialogFinalizeTeacherParticipationOpen(false) }}
                                    onConfirm={() => {
                                        setDialogFinalizeTeacherParticipationOpen(false);
                                        setLoadingExtensionProjectData(true);
                                        finalizeTeacherParticipation();
                                    }}
                                    open={dialogFinalizeTeacherParticipationOpen}
                                />

                                <ConfirmDialog
                                    dialogTitle="Transferir responsabilidade"
                                    dialogDescription="Deseja transferir a responsabilidade do projeto para este usuário?"
                                    onClose={() => { setDialogTransferResponsabilityOpen(false) }}
                                    onConfirm={() => {
                                        setDialogTransferResponsabilityOpen(false);
                                        setLoadingExtensionProjectData(true);
                                        transferResponsability();
                                    }}
                                    open={dialogTransferResponsabilityOpen}
                                />
                            </Grid>

                        )}
                        {name === "Meus relatórios" && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Card>
                                        <ReportsList
                                            extensionProjectId={Number(extensionProject?.id)}
                                            userId={userId}
                                            extensionProjectResponsibleUserId={extensionProject?.responsibleUserId}
                                            isTeacher={
                                                Number(userId) === extensionProject?.responsibleUserId
                                                ||
                                                teacher_ExtensionProjects.some((teacher) => teacher.userId === Number(userId) && teacher.status === 1)
                                            }
                                            showReportForm={
                                                extensionProject?.status === 4
                                                && (
                                                    canEdit ||
                                                    (
                                                        (student_ExtensionProjects
                                                            && student_ExtensionProjects?.some((item) => item.userId === Number(userId) && item.status === 1)
                                                        )
                                                        ||
                                                        (teacher_ExtensionProjects
                                                            && teacher_ExtensionProjects?.some((item) => item.userId === Number(userId) && item.status === 1)
                                                        )
                                                    )
                                                )
                                            }
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                        )}
                        {name === "Aprovação de relatórios" && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Card sx={{ padding: 0 }}>
                                        <ReportsList
                                            title="Relatórios pendentes de aprovação"
                                            extensionProjectId={Number(id)}
                                            status={0}
                                            approval={
                                                Number(userId) === extensionProject?.responsibleUserId
                                                ||
                                                teacher_ExtensionProjects.some((teacher) => teacher.userId === Number(userId) && teacher.status === 1)
                                            }
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                        )}
                        {name === "Histórico do projeto" && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={12} lg={5} xl={6}>
                                            <Card>
                                                <CardHeader
                                                    title={`Histórico de status da participação dos cursos`}
                                                />
                                                <List >
                                                    {extensionProjectStatusLogs?.map((item, index) => (
                                                        <ListItem key={`section-history-primary-${index}`}>
                                                            <ListItemText

                                                                primary={

                                                                    <Box display="flex" justifyContent="space-between" alignItems="center" padding={1}>
                                                                        <Box>
                                                                            <strong>Curso: </strong> {item?.course?.name}
                                                                        </Box>

                                                                        <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
                                                                            <ExtensionProjectStatusChip status={item?.status ?? 99} />
                                                                        </Box>
                                                                    </Box>
                                                                }
                                                                secondary={
                                                                    <Box display="flex" justifyContent="space-between" alignItems="center" padding={1} key={`section-history-secondary-${index}`}>
                                                                        <Box>
                                                                            <Box>
                                                                                <strong>Data: </strong> {format(new Date(item?.createdAt ?? "1900-01-01") ?? new Date(), 'dd/MM/yyyy HH:mm')}
                                                                            </Box >
                                                                            <Box>
                                                                                <strong>Usuário: </strong> {item?.createdBy}
                                                                            </Box >
                                                                        </Box>
                                                                    </Box>
                                                                }
                                                            >

                                                                <Divider />
                                                            </ListItemText>
                                                        </ ListItem>
                                                    ))}
                                                </List>
                                                {(!extensionProjectStatusLogs || extensionProjectStatusLogs?.length === 0) && (
                                                    <CardContent>
                                                        <Typography>
                                                            Nenhum registro encontrado
                                                        </Typography>
                                                    </CardContent>
                                                )}
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={7} xl={6}>
                                            <Card>
                                                <CardHeader
                                                    title={`Motivos de reprovação`}
                                                />
                                                <List >
                                                    {reasonRejections?.map((item, index) => (
                                                        <>
                                                            <ListItem
                                                                key={`section-disapprove-${index}`}
                                                            >
                                                                <Stack direction="row" spacing={1}>
                                                                    <div>
                                                                        <strong>Data: </strong> {format(new Date(item?.createdAt ?? "1900-01-01") ?? new Date(), 'dd/MM/yyyy HH:mm')}
                                                                        <br />
                                                                        <strong>Curso: </strong>{item.course?.name}
                                                                        <br />
                                                                        <strong>Usuário: </strong>{item.user?.name}
                                                                        <br />
                                                                        <strong>Motivo: </strong>{item.description}
                                                                    </div>
                                                                </Stack>

                                                            </ListItem>
                                                            <Divider />
                                                        </>
                                                    ))}
                                                    {(!reasonRejections || reasonRejections?.length === 0) && (
                                                        <CardContent>
                                                            <Typography>
                                                                Nenhum registro encontrado
                                                            </Typography>
                                                        </CardContent>
                                                    )}
                                                </List>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </CustomTabPanel>
                ))}

            </Box>
        </BasePageLayout >
    )

}
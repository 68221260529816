import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
    Box, Button, FormControl, FormHelperText, Grid,
    Icon, IconButton, InputLabel, LinearProgress, List,
    ListItem, ListItemText, MenuItem,
    Paper, Select, SelectChangeEvent, TextField, Tooltip, Typography
} from "@mui/material";

import { ExtensionProjectStatusChip, ConfirmDialog, Conditional } from "../../shared/components";
import {
    IUserResponse, ICourseUserSearchResponse,
    ICourseUserPost, IRolePost, ICourseResponse,
    IRole, IExtensionProjectSearchResponse
} from "../../shared/interfaces";
import { BasePageLayout } from "../../shared/layouts";
import { UserService, CourseService, ExtensionProjectService } from "../../shared/services/api";


export const UserDetail: React.FC = () => {

    const { id } = useParams<'id'>();

    const navigate = useNavigate();

    const [user, setUser] = useState<IUserResponse>();
    const [courses, setCourses] = useState<ICourseUserSearchResponse>();
    const [roles, setRoles] = useState<string[]>([]);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [dialogRoleOpen, setDialogRoleOpen] = useState<boolean>(false);
    const [courseUserDelete, setCourseUserDelete] = useState<ICourseUserPost>({ courseId: 0, userId: 0 });
    const [roleUserDelete, setRoleUserDelete] = useState<IRolePost>({ userId: 0, roleName: "" });
    const [allCourses, setAllCourses] = useState<ICourseResponse[]>([]);
    const [allRoles, setAllRoles] = useState<IRole[]>([]);
    const [selectedCourseId, setSelectedCourseId] = useState<number>(0);
    const [selectedRoleName, setSelectedRoleName] = useState<string>();
    const [errorSelectedCourse, setErrorSelectedCourse] = useState<string>();
    const [errorSelectedRole, setErrorSelectedRole] = useState<string>();
    const [extensionProjectList, setExtensionProjectList] = useState<IExtensionProjectSearchResponse>();

    const [loadingUserData, setLoadingUserData] = useState<boolean>(false);
    const [loadingCoursesUserData, setLoadingCoursesUserData] = useState<boolean>(false);
    const [loadingCoursesUserForm, setLoadingCoursesUserForm] = useState<boolean>(false);
    const [loadingRolesUserData, setLoadingRolesUserData] = useState<boolean>(false);
    const [loadingExtensionProjects, setLoadingExtensionProjects] = useState<boolean>(false);
    const [showFormAddCourse, setshowFormAddCourse] = useState<boolean>(false);
    const [showFormAddRole, setshowFormAddRole] = useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();

    const countRef = useRef(0);

    useEffect(() => {

        if (process.env.NODE_ENV === 'development') {
            if (countRef.current === 0) {
                countRef.current = 1;
                return;
            }
        }

        handleGetUser(Number(id));
        handleGetCoursesByUser(Number(id));
        handleGetUserRoles(Number(id));
        handleGetAllExtensionProjects(Number(id));
    }, [id]);

    const handleGetUser = (id: number) => {

        if (!id) {
            enqueueSnackbar(
                <Typography >
                    Usuário não encontrado
                </Typography>,
                { variant: 'error' });

            navigate("/users")
        }

        setLoadingUserData(true);

        UserService.getById(Number(id))
            .then((result) => {

                if (result instanceof Error) {
                    navigate('/users');
                } else {
                    if (!result) {
                        enqueueSnackbar(
                            <Typography >
                                Usuário não encontrado
                            </Typography>,
                            { variant: 'error' });

                        navigate("/users")
                    }

                    setUser(result);
                }
            })
            .finally(() => {
                setLoadingUserData(false);
            })
    }

    const handleGetCoursesByUser = (id: number) => {

        setLoadingCoursesUserData(true);

        UserService.getCoursesByUser(Number(id))
            .then((result) => {

                if (result instanceof Error) {

                    return;
                } else {
                    if (!result) {
                        enqueueSnackbar(
                            <Typography >
                                Usuário não encontrado
                            </Typography>,
                            { variant: 'error' });

                        navigate("/users")
                    }

                    setCourses(result);
                }
            })
            .finally(() => {
                setLoadingCoursesUserData(false);
            })
    }

    const handleGetUserRoles = (id: number) => {

        setLoadingRolesUserData(true);

        UserService.getRolesByUser(Number(id))
            .then((result) => {

                if (result instanceof Error) {

                    return;
                } else {
                    setRoles(result);
                }
            })
            .finally(() => {
                setLoadingRolesUserData(false);
            })
    }

    const handleDeleteCourse = () => {

        setDialogOpen(false);

        setLoadingCoursesUserData(true);

        UserService.deleteCourseUser(courseUserDelete)
            .then((result) => {

                if (result instanceof Error) {
                    return;
                }

                enqueueSnackbar(
                    <Typography >
                        Curso removido com sucesso.
                    </Typography>
                    , { variant: 'success' });

                handleGetCoursesByUser(Number(id));

            })
            .finally(() => {
                setLoadingCoursesUserData(false);
            })

    }

    const handleDeleteRole = () => {

        setDialogRoleOpen(false);

        setLoadingRolesUserData(true);

        UserService.deleteRoleUser(roleUserDelete)
            .then((result) => {

                if (result instanceof Error) {
                    return;
                }

                enqueueSnackbar(
                    <Typography >
                        Permissão removida com sucesso.
                    </Typography>
                    , { variant: 'success' });

                handleGetUserRoles(Number(id));

            })
            .finally(() => {
                setLoadingRolesUserData(false);
            })

    }

    const handleGetAllCourses = () => {

        if (allCourses.length === 0) {

            setLoadingCoursesUserForm(true);

            CourseService.getAll()
                .then((result) => {

                    if (result instanceof Error) {

                        enqueueSnackbar(
                            <Typography >
                                {result.message}
                            </Typography>
                            , { variant: 'error' });

                        return;
                    }

                    setAllCourses(result);

                })
                .finally(() => {
                    setLoadingCoursesUserForm(false);
                })
        }
    }

    const handleGetAllRoles = () => {

        if (allRoles.length === 0) {

            setLoadingCoursesUserForm(true);

            UserService.getAllRoles()
                .then((result) => {

                    if (result instanceof Error) {
                        return;
                    }

                    setAllRoles(result);

                })
                .finally(() => {
                    setLoadingCoursesUserForm(false);
                })
        }
    }

    const handleOpenAddCourseForm = () => {
        setshowFormAddCourse(true);
        handleGetAllCourses();
        setSelectedCourseId(0);
        setErrorSelectedCourse(undefined);
    };

    const handleOpenAddRoleForm = () => {
        setshowFormAddRole(true);
        handleGetAllRoles();
        setSelectedRoleName(undefined);
        setErrorSelectedRole(undefined);
    };

    const handleAddCourseUser = () => {

        if (selectedCourseId === 0) {
            setErrorSelectedCourse("Selecione um curso");

            return;
        }

        if (courses && courses?.items?.find((course) => { return course.courseId === selectedCourseId })) {
            setErrorSelectedCourse("Curso já vinculado ao usuário");

            return;
        }

        setErrorSelectedCourse(undefined);

        setLoadingCoursesUserForm(true);

        UserService.addCourseUser({
            userId: user?.id,
            courseId: selectedCourseId,
        })
            .then((result) => {

                if (result instanceof Error) {
                    return;
                }

                enqueueSnackbar(
                    <Typography>
                        Curso vinculado com sucesso
                    </Typography>,
                    { variant: 'success' });

                handleGetCoursesByUser(Number(id));

            })
            .finally(() => {
                setLoadingCoursesUserForm(false);
                setshowFormAddCourse(false);
            });
    }

    const handleAddRole = () => {


        if (!selectedRoleName) {
            setErrorSelectedRole("Selecione uma permissão");

            return;
        }

        if (roles && roles.find((role) => { return role === selectedRoleName })) {
            setErrorSelectedRole("Permissão já vinculada ao usuário");

            return;
        }

        setErrorSelectedRole(undefined);

        setLoadingRolesUserData(true);

        UserService.addRole({
            userId: Number(user?.id),
            roleName: selectedRoleName,
        })
            .then((result) => {

                if (result instanceof Error) {
                    return;
                }

                enqueueSnackbar(
                    <Typography>
                        Permissão vinculada com sucesso
                    </Typography>,
                    { variant: 'success' });

                handleGetUserRoles(Number(id));

            })
            .finally(() => {
                setLoadingRolesUserData(false);
                setshowFormAddRole(false);
            });
    }

    const handleChangeSelectCourse = (event: SelectChangeEvent) => {
        setSelectedCourseId(Number(event.target.value));
    };

    const handleChangeSelectRoleName = (event: SelectChangeEvent) => {
        setSelectedRoleName(event.target.value);
    };

    const handleGetAllExtensionProjects = (id: number) => {

        setLoadingExtensionProjects(true);

        ExtensionProjectService.search(
            "",
            id,
            undefined,
            undefined,
            undefined,
            999,
            0)
            .then((result) => {

                if (result instanceof Error) {
                    return;
                }

                setExtensionProjectList(result);

            })
            .finally(() => {
                setLoadingExtensionProjects(false);
            });
    }


    return (
        <BasePageLayout
            title={`Usuário ${user?.name}`}>

            <Grid container rowSpacing={1}>
                <Grid item
                    xs={12}
                    sm={12}
                    md={6}
                    xl={6}
                >

                    <Box
                        margin={1}
                        display="flex"
                        flexDirection="column"
                        component={Paper}
                        sx={
                            {
                                height: '100%',
                                maxHeight: 260,
                            }}
                    >
                        <Conditional condition={loadingUserData}>
                            <Grid item>
                                <LinearProgress variant='indeterminate' />
                            </Grid>
                        </Conditional>

                        <Grid container direction="column" padding={1} spacing={2}>

                            <Grid item>
                                <Typography variant="h6">Dados do usuários</Typography>
                            </Grid>

                            <Grid container item direction="row" spacing={2}>
                                <Grid item
                                    xs={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                >
                                    <TextField
                                        label="Matrícula"
                                        name="registration"
                                        value={user?.registration}
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={2}>
                                <Grid item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <TextField
                                        label="Nome"
                                        name="name"
                                        value={user?.name}
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={2}>
                                <Grid item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <TextField
                                        label="E-mail"
                                        name="email"
                                        fullWidth
                                        value={user?.email}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>

                    </Box>
                </Grid>
                <Grid item
                    xs={12}
                    sm={12}
                    md={6}
                    xl={6}
                >

                    <Box margin={1} display="flex" flexDirection="column" component={Paper}>

                        <Conditional condition={loadingCoursesUserData}>
                            <Grid item>
                                <LinearProgress variant='indeterminate' />
                            </Grid>
                        </Conditional>
                        <Conditional condition={loadingCoursesUserForm}>
                            <Grid item>
                                <LinearProgress variant='indeterminate' />
                            </Grid>
                        </Conditional>

                        <Grid container direction="column" padding={1} spacing={2}>
                            <Conditional condition={!showFormAddCourse}>
                                <Grid item>

                                    <Typography variant="h6">
                                        Cursos
                                        <Tooltip title="Vincular curso ao usuário">
                                            <IconButton onClick={handleOpenAddCourseForm}>
                                                <Icon>add</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>

                                    <List
                                        sx={{
                                            height: '100%',
                                            bgcolor: 'background.paper',
                                            position: 'relative',
                                            overflow: 'auto',
                                            maxHeight: 260,
                                            '& ul': { padding: 0 },
                                        }}
                                    >
                                        {courses?.items?.map((item) => (
                                            <li key={`section-${item.course.acronym}`}>
                                                <ul>
                                                    <ListItem
                                                        key={`section-${item.course.acronym}-${item.course.name}`}
                                                        secondaryAction={
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="delete"
                                                                onClick={
                                                                    () => {
                                                                        setDialogOpen(true);
                                                                        setCourseUserDelete(item as ICourseUserPost);
                                                                    }}
                                                            >
                                                                <Icon>delete</Icon>
                                                            </IconButton>
                                                        }
                                                    >
                                                        <ListItemText primary={`${item.course.acronym} - ${item.course.name}`} />
                                                    </ListItem>
                                                </ul>
                                            </li>
                                        ))}

                                    </List>
                                    {(!courses || courses?.items?.length === 0) && (
                                        <Typography>
                                            Nenhum registro encontrado
                                        </Typography>
                                    )}
                                </Grid>
                            </Conditional>

                            <Conditional condition={showFormAddCourse}>


                                <Grid item>
                                    <Typography variant="h6">Vincular curso ao usuário</Typography>

                                    <Grid container direction="column" padding={1} spacing={2}>


                                        <Grid container item direction="row" spacing={2}>
                                            <Grid item
                                                xs={12}
                                                md={8}
                                                lg={6}
                                                xl={3}
                                            >

                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Curso</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={String(selectedCourseId)}
                                                        label="Curso"
                                                        onChange={handleChangeSelectCourse}
                                                        error={!!errorSelectedCourse}
                                                        disabled={loadingCoursesUserForm}
                                                    >
                                                        {allCourses.map((course) => (
                                                            <MenuItem
                                                                key={course.id}
                                                                value={course.id}
                                                            >
                                                                {course.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <FormHelperText>{errorSelectedCourse}</FormHelperText>
                                            </Grid>
                                        </Grid>

                                        <Grid container item direction="row" spacing={2}>
                                            <Grid item>
                                                <Box
                                                    gap={1}
                                                    display="flex"
                                                >
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleAddCourseUser}
                                                        disabled={loadingCoursesUserForm}
                                                    >
                                                        Adicionar
                                                    </Button>

                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => setshowFormAddCourse(false)}
                                                        disabled={loadingCoursesUserForm}
                                                    >
                                                        Cancelar
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                </Grid>
                            </Conditional>

                        </Grid>

                    </Box>
                </Grid>
                <Grid item xs={12}
                    sm={12}
                    md={6}
                    xl={6}>
                    <Box margin={1} display="flex" flexDirection="column" component={Paper}>

                        <Conditional condition={loadingExtensionProjects}>
                            <Grid item>
                                <LinearProgress variant='indeterminate' />
                            </Grid>
                        </Conditional>

                        <Grid container direction="column" padding={1} spacing={2}>

                            <Grid item>
                                <Typography variant="h6">Projetos de extensão</Typography>
                                <List>
                                    {extensionProjectList?.items?.map((item) => (

                                        <ListItem key={`section-${item.id}`} >
                                            <ListItemText
                                                primary={

                                                    <Box display="flex" justifyContent="space-between" alignItems="center" padding={1}>
                                                        <Box >
                                                            <Typography>
                                                                {item.name} - {item.responsibleUser?.name}
                                                            </Typography>
                                                        </Box>

                                                        <Box display="flex" alignItems="center" flexWrap="nowrap">
                                                            <ExtensionProjectStatusChip status={item?.status ?? 99} />
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="navigate"
                                                                onClick={
                                                                    () => { navigate(`/extension-projects/${item.id}`) }
                                                                }
                                                            >
                                                                <Icon>remove_red_eye</Icon>
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                } />
                                        </ListItem>

                                    ))}

                                </List>

                                {(!extensionProjectList || extensionProjectList?.items?.length === 0) && (
                                    <Typography>
                                        Nenhum registro encontrado
                                    </Typography>
                                )}
                            </Grid>

                        </Grid>

                    </Box>
                </Grid>
                <Grid item xs={12}
                    sm={12}
                    md={6}
                    xl={6}>
                    <Box margin={1} display="flex" flexDirection="column" component={Paper}>

                        <Conditional condition={loadingRolesUserData}>
                            <Grid item>
                                <LinearProgress variant='indeterminate' />
                            </Grid>
                        </Conditional>

                        <Grid container direction="column" padding={1} spacing={2}>

                            <Conditional condition={!showFormAddRole}>
                                <Grid item>
                                    <Typography variant="h6">
                                        Permissões
                                        <Tooltip title="Vincular permissão ao usuário">
                                            <IconButton onClick={handleOpenAddRoleForm}>
                                                <Icon>add</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>
                                    <List
                                        sx={{
                                            height: '100%',
                                            bgcolor: 'background.paper',
                                            position: 'relative',
                                            overflow: 'auto',
                                            maxHeight: 260,
                                            '& ul': { padding: 0 },
                                        }}
                                    >
                                        {roles.map((role) => (
                                            <li key={`section-${role}`}>
                                                <ul>
                                                    <ListItem
                                                        key={`section-${role}-${role}`}
                                                        secondaryAction={
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="delete"
                                                                onClick={
                                                                    () => {
                                                                        setDialogRoleOpen(true);
                                                                        setRoleUserDelete({ userId: Number(user?.id), roleName: role });
                                                                    }
                                                                }
                                                            >
                                                                <Icon>delete</Icon>
                                                            </IconButton>
                                                        }
                                                    >
                                                        <ListItemText primary={`${role}`} />
                                                    </ListItem>
                                                </ul>
                                            </li>
                                        ))}

                                    </List>
                                    <Conditional condition={roles.length === 0}>
                                        <Typography>
                                            Nenhum registro encontrado
                                        </Typography>
                                    </Conditional>
                                </Grid>

                            </Conditional>

                            <Conditional condition={showFormAddRole}>


                                <Grid item>
                                    <Typography variant="h6">Vincular permissão ao usuário</Typography>

                                    <Grid container direction="column" padding={1} spacing={2}>


                                        <Grid container item direction="row" spacing={2}>
                                            <Grid item
                                                xs={12}
                                                md={8}
                                                lg={6}
                                                xl={3}
                                            >
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Permissão</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={String(selectedRoleName)}
                                                        label="Permissão"
                                                        onChange={handleChangeSelectRoleName}
                                                        error={!!errorSelectedRole}
                                                        disabled={loadingRolesUserData}
                                                    >
                                                        {allRoles.map((role) => (
                                                            <MenuItem
                                                                key={role.id}
                                                                value={role.name}
                                                            >
                                                                {role.normalizedName}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <FormHelperText>{errorSelectedRole}</FormHelperText>
                                            </Grid>
                                        </Grid>

                                        <Grid container item direction="row" spacing={2}>
                                            <Grid item>
                                                <Box
                                                    gap={1}
                                                    display="flex"
                                                >
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleAddRole}
                                                        disabled={loadingRolesUserData}
                                                    >
                                                        Adicionar
                                                    </Button>

                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => setshowFormAddRole(false)}
                                                        disabled={loadingRolesUserData}
                                                    >
                                                        Cancelar
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                </Grid>
                            </Conditional>


                        </Grid>

                    </Box>
                </Grid>
            </Grid>

            <ConfirmDialog
                dialogTitle="Remover Curso"
                dialogDescription="Deseja remover este curso deste usuário?"
                onClose={() => { setDialogOpen(false) }}
                onConfirm={handleDeleteCourse}
                open={dialogOpen}
            />

            <ConfirmDialog
                dialogTitle="Remover Permissão"
                dialogDescription="Deseja remover esta permissão deste usuário?"
                onClose={() => { setDialogRoleOpen(false) }}
                onConfirm={handleDeleteRole}
                open={dialogRoleOpen}
            />

        </BasePageLayout >
    )

}

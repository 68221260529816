import { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';

import { useAuthContext, useDrawerContext } from '../shared/contexts';
import {
    Home, About, ExtensionProjectsList, ExtensionProjectsApproval, NotAuthorized,
    ExtensionProjectComputeHours, ExtensionProjectsForm, ExtensionProjectDetail,
    CourseList, CourseForm, DepartmentList, DepartmentForm, UsersList, UserDetail
} from '../pages';
import { Environment } from '../shared/environment';

export const AppRoutes = () => {

    const { setDrawerOptions } = useDrawerContext();
    const { roles } = useAuthContext();

    useEffect(() => {
        setDrawerOptions([
            {
                id: 1,
                label: 'Home',
                icon: 'home',
                path: '/home'
            },
            {
                id: 2,
                label: 'Projetos de extensão',
                icon: 'diversity_2',
                path: '',
                group: [
                    {
                        label: 'Cadastrar',
                        icon: 'create_new_folder',
                        path: '/extension-projects/new/0',
                        role: Environment.ROLE_PROFESSOR
                    },
                    {
                        label: 'Pesquisar',
                        icon: 'search',
                        path: '/extension-projects/search',
                    },
                    {
                        label: 'Aprovação',
                        icon: 'grading',
                        path: '/extension-projects/approval',
                        role: Environment.ROLE_COLEGIADO
                    },
                    {
                        label: 'Computar Horas',
                        icon: 'how_to_reg',
                        path: '/extension-projects/compute-hours',
                        role: Environment.ROLE_GESTOR
                    }
                ]
            },
            {
                id: 3,
                label: 'Configurações',
                icon: 'settings',
                path: '',
                role: Environment.ROLE_ADMIN,
                group: [
                    {
                        label: 'Cursos',
                        icon: 'business',
                        path: '/courses',
                    },
                    {
                        label: 'Departamentos',
                        icon: 'location_city',
                        path: '/departments',
                    },
                    {
                        label: 'Usuários',
                        icon: 'people-all',
                        path: '/users'
                    }
                ]
            }

        ])
    }, []);

    return (
        <Routes>
            <Route path='/about' element={<About />} />

            <Route path='/home' element={<Home />} />

            <Route path='/extension-projects/search' element={<ExtensionProjectsList />} />
            <Route path='/extension-projects/approval' element={roles?.includes(Environment.ROLE_COLEGIADO) ? <ExtensionProjectsApproval /> : <NotAuthorized />} />
            <Route path='/extension-projects/compute-hours' element={roles?.includes(Environment.ROLE_GESTOR) ? <ExtensionProjectComputeHours /> : <NotAuthorized />} />
            <Route path='/extension-projects/new/:id' element={roles?.includes(Environment.ROLE_PROFESSOR) ? <ExtensionProjectsForm /> : <NotAuthorized />} />
            <Route path='/extension-projects/:id' element={<ExtensionProjectDetail />} />

            <Route path='/courses' element={roles?.includes(Environment.ROLE_ADMIN) ? <CourseList /> : <NotAuthorized />} />
            <Route path='/courses/new' element={roles?.includes(Environment.ROLE_ADMIN) ? <CourseForm /> : <NotAuthorized />} />

            <Route path='/departments' element={roles?.includes(Environment.ROLE_ADMIN) ? <DepartmentList /> : <NotAuthorized />} />
            <Route path='/departments/new' element={roles?.includes(Environment.ROLE_ADMIN) ? <DepartmentForm /> : <NotAuthorized />} />

            <Route path='/users' element={roles?.includes(Environment.ROLE_ADMIN) ? <UsersList /> : <NotAuthorized />} />
            <Route path='/users/:id' element={roles?.includes(Environment.ROLE_ADMIN) ? <UserDetail /> : <NotAuthorized />} />

            <Route path='*' element={<Navigate to="/home" />} />
        </Routes>
    );

}
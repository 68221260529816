import { useEffect, useMemo, useRef, useState } from "react";
import {
    FormHelperText,
    Icon,
    IconButton, LinearProgress, Pagination, Paper,
    Table, TableBody, TableCell, TableContainer,
    TableFooter, TableHead, TableRow, TextareaAutosize, Tooltip, Typography
} from "@mui/material";
import * as yup from 'yup';

import { enqueueSnackbar } from "notistack";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ConfirmDialog, ConfirmDialogForm, ExtensionProjectStatusChip } from "../../shared/components";
import { useAuthContext } from "../../shared/contexts";
import { Environment } from "../../shared/environment";
import { IDisapproveExtensionProject, ICourse_ExtensionProjectSearchResponse } from "../../shared/interfaces";
import { BasePageLayout } from "../../shared/layouts";
import { ExtensionProjectService } from "../../shared/services/api";

const formDisapproveValidationSchema: yup.Schema<IDisapproveExtensionProject> = yup.object().shape({
    extensionProjectId: yup.number().required(),
    courseId: yup.number().required(),
    status: yup.number().required(),
    reason: yup.string().required().min(20).max(500),
});

export const ExtensionProjectsApproval = () => {

    const navigate = useNavigate();

    const { userId } = useAuthContext();

    const [loading, setLoading] = useState<boolean>(false);
    const [rowCount, setRowCount] = useState<number>(0);
    const [courseExtensionProjectList, setCourseExtensionProjectList] = useState<ICourse_ExtensionProjectSearchResponse>();
    const [extensionProjectId, setExtensionProjectId] = useState<number>(0);
    const [courseId, setCourseId] = useState<number>(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogFormOpen, setDialogFormOpen] = useState(false);
    const [disaproveReason, setDisaproveReason] = useState("");
    const [erroDisaproveReason, setErroDisaproveReason] = useState<string>();
    const [disaproveStatus, setDisaproveStatus] = useState<number>(2 | 3);

    const [searchParam, setSearchParams] = useSearchParams();

    const page = useMemo(() => {
        return Number(searchParam.get('page') || '1');
    }, [searchParam]);

    const countRef = useRef(0);

    useEffect(() => {

        if (process.env.NODE_ENV === 'development') {
            if (countRef.current === 0) {
                countRef.current = 1;
                return;
            }
        }

        handleSearch();

    }, [page]);

    const handleSearch = () => {

        setLoading(true);

        ExtensionProjectService.getExtensionProjectPendingApproval(userId, page - 1)
            .then((result) => {

                if (result instanceof Error) {
                    return;
                }

                setCourseExtensionProjectList(result);
                setRowCount(result.rowsCount);

            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleApproveExtensionProject = () => {
        setLoading(true);
        setDialogOpen(false)

        ExtensionProjectService.approveExtensionProject({
            extensionProjectId: extensionProjectId,
            courseId: courseId,
        })
            .then((result) => {

                if (result instanceof Error) {
                    return;
                } else {

                    enqueueSnackbar(
                        <Typography >
                            Projeto aprovado com sucesso
                        </Typography>,
                        { variant: 'success' });

                }
                handleSearch();
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handleDisapproveExtensionProject = (status: number) => {

        const request: IDisapproveExtensionProject = {
            extensionProjectId: extensionProjectId,
            status: status,
            courseId: courseId,
            reason: disaproveReason
        }

        formDisapproveValidationSchema
            .validate(request, { abortEarly: false })
            .then(validateData => {
                setLoading(true);
                setDialogFormOpen(false)

                ExtensionProjectService.disapproveExtensionProject(validateData)
                    .then((result) => {

                        if (result instanceof Error) {
                            return;
                        } else {

                            enqueueSnackbar(
                                <Typography >
                                    Projeto reprovado com sucesso
                                </Typography>,
                                { variant: 'success' });

                        }
                        handleSearch();
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            })
            .catch((errors: yup.ValidationError) => {
                setLoading(false);
                setDialogFormOpen(true)

                errors.inner.forEach(error => {
                    if (error.path === "reason") {
                        setErroDisaproveReason(error.message);
                    }
                });

            })
    }

    return (
        <BasePageLayout
            title="Aprovação">

            <ConfirmDialog
                dialogTitle="Aprovar Projeto"
                dialogDescription="Deseja aprovar este projeto?"
                onClose={() => { setDialogOpen(false) }}
                onConfirm={handleApproveExtensionProject}
                open={dialogOpen}
            />



            <ConfirmDialogForm
                dialogTitle="Reprovar Projeto"
                onClose={() => { setDialogFormOpen(false) }}
                onConfirm={() => handleDisapproveExtensionProject(disaproveStatus)}
                open={dialogFormOpen}
            >
                <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Motivo"
                    style={{
                        width: 450,
                        fontFamily: "roboto",
                    }}
                    onKeyDown={() => setErroDisaproveReason(undefined)}
                    onChange={e => setDisaproveReason(e.target.value)}
                    minRows={5}
                />
                {erroDisaproveReason && <FormHelperText style={{ color: "red" }}>{erroDisaproveReason}</FormHelperText>}

            </ConfirmDialogForm>


            <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
                {
                    loading &&
                    <LinearProgress variant='indeterminate' />
                }
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>Professor Responsável</TableCell>
                            <TableCell>Curso</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {courseExtensionProjectList?.items?.map(row => (
                            <TableRow key={`${row.courseId}${row.extensionProjectId}`}>
                                <TableCell>{row.extensionProject.name}</TableCell>
                                <TableCell>{row.extensionProject.responsibleUser?.name}</TableCell>
                                <TableCell>{`${row.course?.acronym} - ${row.course?.name}`}</TableCell>
                                <TableCell>
                                    <ExtensionProjectStatusChip status={row.status} />
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="Visualizar projeto">
                                        <IconButton size="small" onClick={() => navigate(`/extension-projects/${row.extensionProjectId}`)}>
                                            <Icon>remove_red_eye</Icon>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Aprovar projeto">

                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setDialogOpen(true);
                                                setExtensionProjectId(row.extensionProjectId);
                                                setCourseId(row.courseId);
                                            }}
                                            color="success"
                                            disabled={loading}
                                        >
                                            <Icon>check_circle</Icon>
                                        </IconButton>

                                    </Tooltip>

                                    <Tooltip title="Reprovar para revisão">
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setDialogFormOpen(true);
                                                setDisaproveStatus(2);
                                                setExtensionProjectId(row.extensionProjectId);
                                                setCourseId(row.courseId);
                                            }}
                                            color="warning"
                                            disabled={loading}
                                        >
                                            <Icon>do_not_disturb_alt</Icon>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Reprovar definivamente">
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setDialogFormOpen(true);
                                                setDisaproveStatus(3);
                                                setExtensionProjectId(row.extensionProjectId);
                                                setCourseId(row.courseId);
                                            }}
                                            color="error"
                                            disabled={loading}
                                        >
                                            <Icon>cancel</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                    {rowCount === 0 && !loading && (
                        <caption>Nenhum registro encontrado</caption>
                    )}

                    <TableFooter>
                        {
                            rowCount > 0 &&
                            rowCount > Environment.ROWS_LIMIT10 &&
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Pagination
                                        page={page}
                                        count={Math.ceil(rowCount / Environment.ROWS_LIMIT10)}
                                        onChange={(_, newPage) => {
                                            setSearchParams({ page: newPage.toString() }, { replace: true })
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        }
                    </TableFooter>
                </Table>
            </TableContainer>
        </BasePageLayout >
    );
}
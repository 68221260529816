import { useEffect, useMemo, useRef, useState } from "react";
import {
    Box,
    Button, Card, CardActionArea, CardContent,
    CardHeader, Dialog, DialogActions, DialogContent, DialogTitle,
    Divider, Grid, Icon, LinearProgress, Pagination, Paper, Typography
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";

import { ListingPublicExtensionProjectToolbar } from "../../shared/components";
import { Environment } from "../../shared/environment";
import { IPublicExtensionProjectSearchResponse, IPublicExtensionProjectResponse } from "../../shared/interfaces";
import { PublicBasePageLayout } from "../../shared/layouts";
import { ExtensionProjectService } from "../../shared/services/api";
import { useAuthContext } from "../../shared/contexts";

interface IPublicExtensionProjectsListProps {
    onLogin: () => void;
}

export const PublicExtensionProjectsList: React.FC<IPublicExtensionProjectsListProps> = ({
    onLogin
}) => {

    const [searchParam, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [rowCount, setRowCount] = useState<number>(0);
    const [extensionProjectList, setExtensionProjectList] = useState<IPublicExtensionProjectSearchResponse>();
    const [selectedProject, setSelectedProject] = useState<IPublicExtensionProjectResponse | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const searchText = useMemo(() => {
        return searchParam.get('search') || '';
    }, [searchParam]);

    const page = useMemo(() => {
        return Number(searchParam.get('page') || '1');
    }, [searchParam]);

    const countRef = useRef(0);

    const { isAuthenticated } = useAuthContext();

    useEffect(() => {

        if (process.env.NODE_ENV === 'development') {
            if (countRef.current === 0) {
                countRef.current = 1;
                return;
            }
        }

        if (!isAuthenticated)
            handleSearch();

    }, [page]);

    const handleSearch = () => {
        setLoading(true);
        ExtensionProjectService.searchPublicProjects(
            searchText,
            undefined,
            page - 1
        )
            .then((result) => {
                if (result instanceof Error) {
                    return;
                }
                setExtensionProjectList(result);
                setRowCount(result.rowsCount);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleOpenDialog = (project: IPublicExtensionProjectResponse) => {
        setSelectedProject(project);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSelectedProject(null);
    };

    return (
        <PublicBasePageLayout
            title="Projetos de Extensão Públicos"
            toolbar={(
                <ListingPublicExtensionProjectToolbar
                    searchText={searchText}
                    onChangeSearchText={text => setSearchParams({
                        search: text,
                        page: page.toString()
                    }, { replace: true })}
                    onClickSearch={() => handleSearch()}
                />
            )}
            onLogin={onLogin}
        >
            <main>
                <Paper
                    variant="outlined"
                    sx={{
                        padding: 2,
                        margin: 1,
                        boxSizing: 'border-box',
                        flexGrow: 1,
                    }}
                >
                    {loading && <LinearProgress variant="indeterminate" sx={{ marginBottom: 2 }} />}

                    <Grid
                        container
                        spacing={3}
                        justifyContent="center"
                        alignItems="stretch"
                    >
                        {extensionProjectList?.items?.map((project, index) => (
                            <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={3}>
                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                        borderRadius: 3,
                                        boxShadow: 4,
                                        transition: 'transform 0.2s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                            boxShadow: 6,
                                        },
                                    }}
                                >
                                    <CardActionArea onClick={() => handleOpenDialog(project)}>
                                        <CardHeader
                                            title={`Projeto #GEEU${project.id}`}
                                            titleTypographyProps={{ variant: 'h6', component: 'h2', color: 'white' }}
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: 'white',
                                                paddingY: 1,
                                                paddingX: 2,
                                                borderTopLeftRadius: 3,
                                                borderTopRightRadius: 3,
                                                textAlign: 'center',
                                            }}
                                        />
                                        <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: 2, overflow: 'hidden' }}>
                                            <Box sx={{ mb: 2 }}>
                                                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                    <Icon sx={{ mr: 1 }}>description</Icon>
                                                    {project.name}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                    <Icon sx={{ mr: 1 }}>person</Icon>
                                                    Responsável: Professor {project.responsibleUser?.name}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                    <Icon sx={{ mr: 1 }}>date_range</Icon>
                                                    De {format(new Date(project.startDate ?? "1900-01-01"), "dd/MM/yyyy")} a {format(new Date(project.endDate ?? "1900-01-01"), "dd/MM/yyyy")}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{ textAlign: 'justify', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                >
                                                    {project.description
                                                        ? (project.description.length > 150 ? `${project.description.substring(0, 150)}...` : project.description)
                                                        : 'Descrição não disponível.'}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                    {/* Mensagem de "nenhum registro encontrado" */}
                    {rowCount === 0 && !loading && (
                        <Typography variant="body2" align="center" color="text.secondary" sx={{ mt: 2 }}>
                            Nenhum registro encontrado
                        </Typography>
                    )}

                    {/* Paginação */}
                    {rowCount > 0 && rowCount > Environment.ROWS_LIMIT10 && (
                        <Pagination
                            sx={{ mt: 4, display: "flex", justifyContent: "center" }}
                            page={page}
                            count={Math.ceil(rowCount / Environment.ROWS_LIMIT10)}
                            onChange={(_, newPage) => {
                                setSearchParams(
                                    { search: searchText, page: newPage.toString() },
                                    { replace: true }
                                );
                            }}
                        />
                    )}
                </Paper>

                {/* Dialog de detalhes do projeto de extensão */}
                <Dialog
                    open={isDialogOpen}
                    onClose={handleCloseDialog}
                    maxWidth="md"
                    fullWidth
                    PaperProps={{
                        sx: {
                            borderRadius: 2,
                            padding: 2,
                        },
                    }}
                >
                    <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Icon>info</Icon> Detalhes do Projeto de Extensão
                    </DialogTitle>
                    <DialogContent dividers>
                        {selectedProject && (
                            <Box sx={{ flexDirection: 'column', gap: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                            <Icon color="primary">label</Icon>
                                            <Typography variant="h6">Nome:</Typography>
                                        </Box>
                                        <Typography paragraph>{selectedProject.name}</Typography>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                            <Icon color="primary">person</Icon>
                                            <Typography variant="h6">Responsável:</Typography>
                                        </Box>
                                        <Typography paragraph>Professor(a) {selectedProject.responsibleUser?.name}</Typography>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                            <Icon color="primary">calendar_today</Icon>
                                            <Typography variant="h6">Data Início:</Typography>
                                        </Box>
                                        <Typography paragraph>{format(new Date(selectedProject.startDate ?? "1900-01-01"), 'dd/MM/yyyy')}</Typography>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                            <Icon color="primary">event</Icon>
                                            <Typography variant="h6">Data Fim:</Typography>
                                        </Box>
                                        <Typography paragraph>{format(new Date(selectedProject.endDate ?? "1900-01-01"), 'dd/MM/yyyy')}</Typography>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                            <Icon color="primary">contact_mail</Icon>
                                            <Typography variant="h6">Contato Público:</Typography>
                                        </Box>
                                        <Typography paragraph>{selectedProject.publicContactDescription}</Typography>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                            <Icon color="primary">place</Icon>
                                            <Typography variant="h6">Endereço Público:</Typography>
                                        </Box>
                                        <Typography paragraph>{selectedProject.publicAddress || 'Endereço não disponível.'}</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider sx={{ my: 2 }} />
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                            <Icon color="primary">description</Icon>
                                            <Typography variant="h6">Descrição:</Typography>
                                        </Box>
                                        <Typography paragraph sx={{ textAlign: 'justify' }}>
                                            {selectedProject.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center', padding: 2 }}>
                        <Button onClick={handleCloseDialog} color="primary" variant="contained" sx={{ borderRadius: 50, paddingX: 3 }}>
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
            </main>

        </PublicBasePageLayout>
    );
};
